<template>
  <div class="gx-section gx-section--media" v-observe-visibility="visibilityChanged">
    <div class="container">
      <h1><span class="text-color-3">News</span> <br>GX in Media</h1>      
      <VueSlickCarousel :arrows="true" :dots="true" v-bind="settings" class="media-slider">
        <BlogPost
          :smallHeading="`Raj Abrol`"
          :heading="`CreditX is live for Indian banks: A GenAI credit agent automating credit tailored to banks’ needs`"
          :content="`This innovative tool aims to enhance credit delivery...`"
          :source="`news16.jpg`"
          :link="`news16`"
          v-on:learnMore="onBlogPostLearnMore"
        />
        <BlogPost
          :smallHeading="`Eri Ishikawa`"
          :heading="`Galytix collaborates with Foyer Global Health to implement the HealthX platform`"
          :content="`HealthX will automate health insurance-related...`"
          :source="`news15.jpg`"
          :link="`news15`"
          v-on:learnMore="onBlogPostLearnMore"
        />
        <BlogPost
          :smallHeading="`Marc Nourse`"
          :heading="`GX Automates Corporate Lending for Global Banks`"
          :content="`Galytix Extends its Generative AI Data Platform to Automate Corporate Credit...`"
          :source="`news14.jpg`"
          :link="`news14`"
          v-on:learnMore="onBlogPostLearnMore"
        />
        <BlogPost
          :smallHeading="`Raj Abrol`"
          :heading="`Raj Abrol was a part of a distinguished panel at the ICT Spring Conference held in Luxembourg`"
          :content="`CEO Raj Abrol talk about how the GX platform...`"
          :source="`news13.jpg`"
          :link="`news13`"
          v-on:learnMore="onBlogPostLearnMore"
        />
        <BlogPost
          :smallHeading="`Rupak Ghose`"
          :heading="`Current Trends Impacting Financial Services`"
          :content="`Rupak Ghose, COO at Galytix, to this episode of FinTech Focus TV, hosted by Toby Babb....`"
          :source="`news12.jpg`"
          :link="`news12`"
          v-on:learnMore="onBlogPostLearnMore"
        />
        <BlogPost
          :smallHeading="`Rupak Ghose`"
          :heading="`Galytix, a modern brick for risk assessment`"
          :content="`Banks are poorly or not at all equipped to assess business risks at an early stage, according...`"          
          :source="`news3.jpg`"
          :link="`https://delano.lu/article/bank-risk-assessment-insuffici`"
          v-on:learnMore="onBlogPostLearnMore"
        />
        <BlogPost
          :smallHeading="`Rupak Ghose`"
          :heading="`Bank risk managers aren't ready for wave of corporate credit defaults`"
          :content="`The loose monetary and fiscal policy put in place by major central banks...`"          
          :source="`news5.jpg`"
          :link="`https://www.americanbanker.com/opinion/bank-risk-managers-arent-ready-for-wave-of-corporate-credit-defaults`"
          v-on:learnMore="onBlogPostLearnMore"
        />
        <BlogPost
          :smallHeading="`Rupak Ghose`"
          :heading="`Finance isn’t data-ready for ESG`"
          :content="`The «gold rush» moment of ESG in finance reveals that data models used by banks and insurers...`"          
          :source="`news6.jpg`"
          :link="`https://www.finews.com/news/english-news/48526-rupak-ghose-esg-finance-data`"
          v-on:learnMore="onBlogPostLearnMore"
        />        
        <BlogPost
          :smallHeading="`Raj Abrol`"
          :heading="`Galytix, the comet of banking and insurance`"
          :content="`Raj Abrol may be a young entrepreneur, but he already has a perfect grasp of the art of...`"          
          :source="`news7.jpg`"
          :link="`https://geeky.news/galytix-the-comet-of-banking-and-insurance/`"
          v-on:learnMore="onBlogPostLearnMore"
        />    
        <BlogPost
          :smallHeading="``"
          :heading="`Consumer behaviour and carbon taxes matter too`"
          :content="`Keep abreast of significant corporate, financial and political developments around the world. Stay informed and spot...`"          
          :source="`news4.jpg`"
          :link="`https://www.ft.com/products`"
          v-on:learnMore="onBlogPostLearnMore"
        />    
        <BlogPost
          :smallHeading="`Raj Abrol`"
          :heading="`Galytix successfully closes another fundraising for global expansion`"
          :content="`Galytix, a high growth AI driven FinTech firm announces...`"
          :source="`news1.jpg`"
          :link="`news1`"
          v-on:learnMore="onBlogPostLearnMore"
        />
        <BlogPost
          :smallHeading="`Raj Abrol`"
          :heading="`Galytix signs a global partnership with Societe Generale`"
          :content="`Societe generale and Galytix, supported by pwc, sign a global...`"          
          :source="`news2.jpg`"
          :link="`news2`"
          v-on:learnMore="onBlogPostLearnMore"
        />
        <BlogPost
          :smallHeading="`Raj Abrol`"
          :heading="`Fintech startup galytix seeks to transform data analysis`"
          :content="`Bankers, fund managers, insurers and the Big Four dreamed of it...`"          
          :source="`news8.jpg`"
          :link="`https://delano.lu/article/delano_fintech-startup-galytix-seeks-transform-data-analysis`"
          v-on:learnMore="onBlogPostLearnMore"
        />        
        <BlogPost
          :smallHeading="`Raj Abrol`"
          :heading="`Galytix, le graal des financiers et des assureurs`"
          :content="`Les banquiers, les gestionnaires de fonds, les assureurs et les Big Four en rêvaient...`"          
          :source="`news9.jpg`"
          :link="`https://paperjam.lu/article/galytix-graal-financiers-et-as`"
          v-on:learnMore="onBlogPostLearnMore"
        />        
        <BlogPost
          :smallHeading="``"
          :heading="`Irish-linked firm targets $100m revenue`"
          :content="`The AI-based smart data analytics business uses machine learning to curate and synthesise unstructured...`"          
          :source="`news10.jpg`"
          :link="`https://www.independent.ie/business/technology/irish-linked-firm-targets-100m-revenue-39357998.html`"
          v-on:learnMore="onBlogPostLearnMore"
        />        
        <BlogPost
          :smallHeading="`Raj Abrol`"
          :heading="`Smart data analytics fintech Galytix secures investment`"
          :content="`Galytix, an AI-based smart data analytics business which uses machine...`"          
          :source="`news11.jpg`"
          :link="`https://www.finextra.com/pressarticle/83142/smart-data-analytics-fintech-galytix-secures-investment`"
          v-on:learnMore="onBlogPostLearnMore"
        />        
                
      </VueSlickCarousel>      
    </div>    
    
    <div id="news1" class="gx-popup" style="display: none;" ref="news1">
      <div class="gx-popup-content-wrap">
        <div class="news-content popup-content">
          <div class="popupClose" v-on:click="onPopupClose('news1')">X</div>
          <div class="banner banner-news-1"></div>
          <div class="popup-body">
            <h1>Galytix also announces the appointment of senior management in Rupak Ghose as COO and Jeff Tilson as CCO</h1>
            <hr />
            <p>Galytix, a high growth AI driven FinTech firm announces the successful completion of a fundraising. The firm deploys integrated data ecosystems for global financial players delivering Early Warning Signals (EWS) and advanced predictive analytics. Galytix’s solutions have consistently outperformed peers in speed of delivery and accuracy across multiple internal and external data sets. The capital raised will be used to support its growth ambitions in Europe responding to growing demand in the banking, capital markets, asset management and insurance sectors. The funds raised will be deployed in client focused R&amp;D and allow Galytix to expand its European operations in Switzerland, the Czech Republic and soon its additional R&amp;D base in Luxembourg.</p>
            <p>Raj Abrol, founder and CEO, welcomed the support of sophisticated investors as validation of Galytix's technology and development. “We are delighted to have secured development funding from a sophisticated investor group, which facilitates the scale-up of Galytix and commercial expansion”, he added. “Capital from new investors, together with additional funding commitments obtained from investors over the past 18 months, allows significant solution enhancement as well as the potential to engage with multiple global clients.”</p>
            <p>The Group will benefit from the appointment of Rupak Ghose, a financial industry technology expert and recognized thought leader who becomes Galytix's Chief Operating Officer. Based in London, Ghose’s experience extends to 20 years advising senior managers, investors, boards, regulators and industry bodies. Recently, as group head of strategy for ICAP/NEX he was part of a management team that transformed the business into a fintech leader generating shareholder returns of ~250%. Ghose began his career in institutional research, the majority of which was spent at Credit Suisse. He was consistently one of the top-rated institutional equity research analysts in diversified financials.</p>
            <p>The appointment of Ghose follows that of Jeff Tilson who joined Galytix as Group Chief Commercial Officer earlier in the year. His role includes overseeing the opening of Galytix’s Zurich office to better serve Galytix’s large insurance clients in Switzerland as well as the creation of Galytix’s DataFactory hub in Prague to deliver the GX Platform to clients across Europe. Tilson brings some three decades of experience to Galytix at the nexus of business and technology, primarily at Accenture and Swiss Re. During his near 17-year tenure at Swiss Re, Tilson drove the creation of the new Swiss Re Institute, oversaw its operating model and managed its Data &amp; Analytics research portfolio.</p>
            <p>Investment in cutting edge R&amp;D sits at the core of Galytix since its launch six years ago. Their global technology and data science headcount is now in excess of 100 engineers engaged in the roll out of Galytix’s end-to-end data platform to financial industry players. The addition of R&amp;D and Delivery operations in Prague, Czech Republic compliments its expanding R&amp;D and Delivery centre in Gurgaon, India.</p>
            <p>The Galytix GX platform digitises manual &amp; data heavy processes, builds a connected data ecosystem and delivers early warning signals through its proprietary AI-driven predictive algorithms allowing business experts in banks, insurers and asset managers to make better, more informed risk and commercial decisions. Galytix allows harmonization of data from a disparate array of internal and external systems and sources, efficiently and at high-speed, thereby enhancing clients’ core analysis and decisions allowing clear measurable ROI to its clients. Galytix believes it is the only fully functional holistic solution of its kind.</p>
            <p>“We launched Galytix because we saw a significant opportunity for banks, insurers and asset managers to generate growth and capture efficiency gains from replacing legacy in-house systems with an end-to-end data platform. The firm is entering the scale up stage with this expansion of product capabilities and infrastructure. I am delighted that Rupak will join our executive management team following the addition of Jeff. Rupak is a recognised leader in the fintech and data sector with a track record of generating significant value for shareholders and clients”: Raj Abrol CEO Galytix.</p>
            <p>“The experience of Covid-19 and the emerging opportunities and risks around ESG, AI and the crypto economy have taught me that the world is changing more rapidly than at any point in my career and risk managers are crying out for more technology solutions that can provide early warning signals. When Raj asked me to come join him in this journey, I jumped at the opportunity” Rupak Ghose COO Galytix.</p>
            <p>PwC Luxembourg assisted Galytix with the transaction having formed a Commercial Alliance with Galytix in 2020. Matt Moran, a senior Advisory Partner and the firm’s Global Alliances Leader, said he is delighted to have supported Raj and the board and management on their journey. “Galytix helps solve the growing data problem pervasive across financial services and is universally applicable across virtually all players”. He added that “the technology and approach of Galytix can greatly simplify the ability of banks, insurers and asset managers to create the right ‘data cocktail’ to deliver the key insights executives require to manage their businesses. We expanded our collaboration with Galytix this year. Together with PwC’s deep industry knowledge and strong data, regulatory and transformation capabilities, we can accelerate Galytix’s platform benefits for our clients”.</p>
            <p>Galytix was advised on the deal by Tech City lawyers Temple Bright LLP.</p>        
            <p>For further media information, contact Monika Soucek at <a href="mailto:media@galytix.com" class="text-link">media@galytix.com</a></p>
          </div>
        </div>  
      </div>    
    </div>

    <div id="news2" class="gx-popup" style="display: none;" ref="news2">
      <div class="gx-popup-content-wrap">
        <div class="news-content popup-content">
           <div class="popupClose" v-on:click="onPopupClose('news2')">X</div>
          <div class="banner banner-news-2"></div>
          <div class="popup-body">
            <h1>Societe Generale and Galytix, supported by PwC, sign a global partnership to transform their group-wide credit processes</h1>
            <hr>
            <div class="news-companies-logo">
            <div class="company-logo" style="width: 60%;"><img class="newsGalytixLogo" src="../../assets/imgs/news/gx-logo.png" alt="Galytix logo" /></div>
            <div class="company-logo"><img class="newsSocGenLogo" src="../../assets/imgs/news/soc-gen_logo.png" alt="Societe Generale logo"></div>
            <div class="company-logo"><img class="newsSocGenLogo" src="../../assets/imgs/news/pwc_logo.png" alt="PwC logo"></div> 
            </div>
            <p>Leading European financial services group Societe Generale has entered into an agreement with AI-powered fintech Galytix to digitalise the Societe Generale Corporate and Investment Banking group’s financial credit analysis processes.</p>
            <p>Societe Generale is leading the way amongst its peers in developing a credit data model which will redefine how its credit analysts access and harness unstructured and structured data to make faster and better credit decisions.</p>
            <p>Galytix, an AI-based business founded in London in 2015, has built a smart data platform which uses proprietary market leading technology to ingest, transform, curate and spread unstructured and structured data in an end-to-end automated data pipeline covering the entire data lifecycle.</p>
            <p>Galytix and PwC Luxembourg &amp; France partnered to develop this project for Societe Generale, with Galytix providing its leading edge technology, and PwC its market insights and consulting support.</p>
            <p>Galytix CEO Raj Abrol said: “We are excited to be serving Societe Generale in this important project. The solution we are developing with Societe Generale offers us the opportunity to showcase our end to end data platform capabilities. We thank Societe Generale for their confidence in us.”</p>
            <p>PwC partner &amp; alliance leader Matt Moran said: “From our first meeting with Galytix in 2018 we recognised the potential for their smart-data platform to accelerate solving the data engineering challenges faced by clients”. PwC Luxembourg entered an alliance with Galytix to power Societe Generale with these new technologies.</p>
            <p>Galytix was advised on the contract by Tech City lawyers Temple Bright LLP.</p>
            <p>For further media information, contact&nbsp;<a href="mailto:info@galytix.com" class="text-link">info@galytix.com</a>, or&nbsp;<a href="mailto:youcef.damardji@pwc.com" class="text-link">youcef.damardji@pwc.com</a></p>
          </div>
        </div>  
      </div>    
    </div>

    <div id="news13" class="gx-popup" style="display: none;" ref="news13">
      <div class="gx-popup-content-wrap">
        <div class="news-content popup-content">
           <div class="popupClose" v-on:click="onPopupClose('news13')">X</div>
          <!-- <div class="banner banner-news-13"></div> -->
          <div class="popup-body" v-if="openNewsPopup === 'news13'">
            <h1>Raj Abrol was a part of a distinguished panel at the ICT Spring Conference held in Luxembourg</h1>
            <hr>
            <iframe id="videoIframe13" width="100%" height="500" src="https://www.youtube-nocookie.com/embed/e3mKy6DVwzs?enablejsapi=1&amp;rel=0&amp;iv_load_policy=3&amp;autoplay=0&amp;mute=1?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
            <p>CEO Raj Abrol talk about how the GX platform helps private, SME and corporate banks bring lending ‘time to decision’ down from around a month to 24 hours through automation.</p>
            
          </div>
        </div>  
      </div>    
    </div>
    <div id="news12" class="gx-popup" style="display: none;" ref="news12">
      <div class="gx-popup-content-wrap">
        <div class="news-content popup-content">
           <div class="popupClose" v-on:click="onPopupClose('news12')">X</div>
          <!-- <div class="banner banner-news-13"></div> -->
          <div class="popup-body" v-if="openNewsPopup === 'news12'">
            <h1>Current Trends Impacting Financial Services</h1>
            <hr>
            <iframe id="videoIframe12" width="100%" height="500" src="https://www.youtube-nocookie.com/embed/dS6BPMYHy_A?enablejsapi=1&amp;rel=0&amp;iv_load_policy=3&amp;autoplay=0&amp;mute=1?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
            <p>Rupak Ghose, COO at Galytix, to this episode of FinTech Focus TV, hosted by Toby Babb.</p>
            
          </div>
        </div>  
      </div>    
    </div>  
    <div id="news14" class="gx-popup" style="display: none;" ref="news14">
      <div class="gx-popup-content-wrap">
        <div class="news-content popup-content">
          <div class="popupClose" v-on:click="onPopupClose('news14')">X</div>
          <div class="banner banner-news-14"></div>
          <div class="popup-body">
            <h1>Galytix Extends its Generative AI Data Platform to Automate Corporate Credit Lending for Global Banks</h1>
            <hr />
            <p>LONDON, 18 October 2023 – <a href="https://www.galytix.com/" class="text-link">Galytix</a>, the Gen-AI data technology company for financial institutions, today announced the expansion of its next generation GX data platform, which is now being used by global banks to automate corporate credit lending and generate early warning signals.</p>
            <p>Founded in 2015, Galytix developed a market-leading data operating system powered by generative AI that engineers and unifies the end-to-end data flow for financial institutions. Its proprietary GX Platform discovers, ingests, curates and ontologises structured and unstructured data using algorithmic, end-to-end pipelines and intelligent metadata.</p>
            <p>GX’s Gen-AI models are trained on proprietary data for the financial services industry and address multiple use cases across banking and insurance. These trained models bring siloed, intercloud data into a single, usable ecosystem that generate early warning signals tailored to financial institutions.</p>
            <p>In banking, the GX platform’s extensive automative functions and data analysis allow clients to streamline the lending journey for corporate banking clients, resulting in faster, more effective credit decisions. It also frees corporate banking teams to focus more on client service while providing them with usable and reliable data-driven credit insights.</p>
            <p>Galtyix clients include <a rel="noopener" href="https://www.societegenerale.com/en" target="_blank" class="text-link">Societe Generale</a>, which uses the GX platform to manage its data flow for corporate credit ratings and analysis globally. This includes everything from data ingestion to preparing client credit analysis.</p>
            <p>“We fundamentally believe that relationship managers and credit analysts at banks should be supported by AI features that automate routine work and increase productivity. It’s like having an analyst on demand,” said Raj Abrol, CEO, Galytix. “The approach we have developed for global banks like Societe Generale represents a step change in how frontline teams use technology to improve credit decision-making and risk monitoring. We are proud to be expanding our work to transform the siloed data that exists across the banking industry into a connected data ecosystem that delivers a seamless experience for users and actionable business intelligence.”</p>
            <h2>About Galytix</h2>
            <p>Galytix is a market-leading GenAI-driven data technology company that is dedicated to serving financial institutions. Its GX platform algorithmically engineers every aspect of the data lifecycle and ingests, curates, and transforms structured and unstructured data into a unified, usable, proprietary data ecosystem that creates a golden source of truth for clients. The company was founded in 2015 and has over 100 employees and data engineers in key markets around the world who serve leading financial companies.</p>
            <p>For more information, visit <a href="https://www.galytix.com/" class="text-link">www.galytix.com</a></p>
            <h2>Media Contact</h2>
            <p>Jennifer Berlin<br />Forefront Communications<br />+44 (0) 7375 288641<br /><a href="mailto:jberlin@forefrontcomms.com" class="text-link">jberlin@forefrontcomms.com</a></p>
            <br/>
            <a rel="noopener" href="https://www.prnewswire.com/news-releases/galytix-extends-its-generative-ai-data-platform-to-automate-corporate-credit-lending-for-global-banks-301959411.html" target="_blank" class="btn-sm-primary text-color">Press release</a>
          </div>
        </div>  
      </div>    
    </div>
    
    <div id="news15" class="gx-popup" style="display: none;" ref="news15">
      <div class="gx-popup-content-wrap">
        <div class="news-content popup-content">
          <div class="popupClose" v-on:click="onPopupClose('news15')">X</div>
          <div class="banner banner-news-15"></div>
          <div class="popup-body">
            <h1>Foyer Global Health to collaborate with Galytix on GenAI digital health platform ‘HealthX’</h1>
            <em class="text-color">HealthX will automate health insurance-related underwriting and claims processes, delivering lasting performance and productivity gains</em>
            <hr />
            <p>LONDON, 23 January 2024 - <a href="https://www.galytix.com" class="text-link">Galytix</a>, the GenAI platform for the financial sector, and <a rel="noopener" href="https://www.foyerglobalhealth.com" target="_blank" class="text-link">Foyer Global Health</a>, the international health insurer for expatriates and companies, today announced a collaboration to implement HealthX, the digital health platform for insurers.</p>
            <p>The International Private Medical Insurance (IPMI) market is built on data that changes significantly on a regular basis. HealthX uses generative AI to combine structured and unstructured data from multiple sources to produce insights, analytics and alerts that enhance business propositions, customer service, and performance. The range of services offered by HealthX will include portfolio analytics, claims alerts and processing, underwriting and repricing, and risk reporting.</p>
            <p>Galytix recently went live with its HealthX platform with an initial focus on providing portfolio analytics and automating core claims processes for health risks. HealthX allows users to score claims within seconds, removing the need for an individual to read through each claim and make that decision. The choice of this solution is part of Foyer Global Health’s long-term strategy of having a standardised data model and consolidated view on health data. The implementation with Foyer Global Health is delivering decisions at a quicker pace and reducing the amount of time spent on gathering and processing data from days to a few minutes. It is not only freeing up staff to focus on business growth, but also significantly improving the processing times and data quality.</p>
            <p>“HealthX’s powerful generative AI capabilites and easy interface, which take the form of a ‘Health Agent’, enable users to understand a topic faster, uncover new viewpoints and health insights, and get things done more easily,” said Raj Abrol, CEO, Galytix. “Let’s take a question like ‘what are the high risk claims?’ Normally, a user might break this question into smaller ones, sort through the vast information available, and start to piece things together. With Health Agent, users can get an answer by simply asking the question in plain English. It’s like having an analyst on demand that automates routine work and increases productivity.”</p>
            <p>“It’s difficult to imagine the sheer volume of data generated by health insurance policies, especially considering the various formats we receive it in,” said François Jacquemin, CEO, Foyer Global Health. “This platform is fundamental to our growth as a business and will transform the way we work with health data by providing us a ‘golden source of truth’. We’re excited by the efficiency gains and risk insights we are already generating by leveraging AI at this early stage.”</p>
            <h2>About Foyer Global Health</h2>
            <p>Foyer Global Health is the international health insurance and service provider for globally mobile people, backed by Luxembourg’s number one insurer, Foyer S.A.. Foyer Global Health’s plans are tailored to the needs of individuals and groups around the world and provides first-class coverage with comprehensive medical assistance and value-added health care services.</p>
            <p>Additional information about Foyer Global Health can be found at <a rel="noopener" href="https://www.foyerglobalhealth.com" target="_blank" class="text-link">https://www.foyerglobalhealth.com</a></p>
            <h2>About Galytix</h2>
            <p>Galytix is a market-leading provider of a GenAI platform dedicated to serving financial institutions. Its GX platform provides access to the latest insights, analytics and alerts by algorithmically engineering structured and unstructured data into a connected data ecosystem. The company was founded in 2015 and has over 100 data scientists and data engineers in key markets around the world who serve leading financial companies.</p>
            <p>For more information, visit <a href="https://www.galytix.com" class="text-link">www.galytix.com</a></p>
            <h2>Media Contact</h2>
            <p>Jennifer Berlin<br />Forefront Communications<br />+44 (0) 7375 288641<br /><a href="mailto:jberlin@forefrontcomms.com" class="text-link">jberlin@forefrontcomms.com</a></p><br/>
            <a rel="noopener" href="https://www.prnewswire.co.uk/news-releases/foyer-global-health-to-collaborate-with-galytix-on-genai-digital-health-platform-healthx-302040784.html" target="_blank" class="btn-sm-primary text-color">Press release</a>
          </div>
        </div>  
      </div>    
    </div>

    <div id="news16" class="gx-popup" style="display: none;" ref="news16">
      <div class="gx-popup-content-wrap">
        <div class="news-content popup-content">
          <div class="popupClose" v-on:click="onPopupClose('news16')">X</div>
          <div class="banner banner-news-16"></div>
          <div class="popup-body">
            <h1>CreditX is live for Indian banks: A GenAI credit agent automating credit tailored to banks’ needs</h1>
            <em class="text-color">This innovative tool aims to enhance credit delivery by enabling banks to streamline their credit processes efficiently, quickly, and cost-effectively</em>
            <hr />
            <p>Galytix, a London-headquartered, GenAI-focused fintech, has launched a game-changing Generative AI-based Credit Agent solution, CreditX, for the Indian banking sector that would help banks here to deliver credit better, faster and cheaper.</p>
            <p>“Think of it (CreditX) as a Credit Analyst on demand. We are now bringing CreditX to India as this journey of an Indian economy that is now $3.5 trillion and moving towards $5 trillion is going to have a significant credit boom and human capital is not going to be able to serve this boom”, Raj Abrol, Co-founder and CEO, Galytix told <em>businessline</em> here.</p>
            <p>Generative AI is a recently developed type of artificial intelligence — a general-purpose technology — capable of producing new and original content such as text, images, videos, and audio.</p>
            <p>This is achieved by learning statistical patterns from existing data and then using these patterns to generate new and novel outputs upon request.</p>
            <p>“This (the launch of CreditX) could be a game-changing initiative on GenAI’s side for the Indian banking sector, which is absolutely critical for the growth of the economy to the 2030 aspiration,” Abrol said.</p>
            <p>He said that Galytix is in an exploratory stage in India to see where this technology fits well. “We want to partner with government institutions. We don’t want to focus only on the private sector. We want to broaden the application of GenAI technology in banking sector,” Abrol added.</p>
            <p>Galytix, through CreditX, has a credit agent ready to automate all credit-related tasks in a banking set-up. “The productivity gains you can generate are 20-30 per cent at front office level and 30-40 per cent at mid-office level.</p>
            <p>Think of it as an agent that every banker can connect with and gives you all the info for your decisions,” he said.</p>
            <p>Abrol also highlighted that India has a credit gap of $300-500 billion, mostly in the MSME space,  that needs to be filled, and GenAI-based CreditX can boost credit flow to MSMEs in this area.</p>
            <p>“So far we have not seen globally any bank  where a credit agent behaves like an analyst on demand and covers the full credit life cycle. This credit agent will help penetrate the untapped market that is part of 67 million MSMEs in India but is taking unofficial credit”, Abrol said.</p>
            <p>Abrol also claimed that Galytix is the only company that has implemented GenAI-based credit agent solutions across the world in multiple banks. CreditX is implemented in multiple banks abroad, he said, adding that it is also now being used by a foreign bank based in Bengaluru.</p>
            <p>Banks encounter numerous hurdles in efficiently managing Counterparty Credit Risk (CCR), such as the absence of an end-to-end credit intelligence engine with integrated workflow functionality.</p>
            <p>Excel and Word are the primary tools Relationship Managers (RMs) and Credit Analysts (CAs) use today. </p>
            <p>CreditX addresses these challenges by automating the CCR journey through a tailored Credit Agent solution. The solution offers RMs and CAs the ability to automate their routine tasks, allowing them to focus on high-value activities such as addressing clients’ needs and monitoring complex client situations.</p>
            <p>CreditX solves challenges related to information gathering, credit memorandum preparation, credit decisions, post-approval monitoring, and risk reporting. </p>
            <p>CreditX offers flexible, accurate risk reporting empowered by the full potential of data analytics, providing banks with actionable insights to drive decision-making, he said.</p>
            <p>“Galytix’s CreditX aims to revolutionize credit lending in India by addressing these challenges and offering unparalleled efficiency and accuracy through advanced GenAI technology,” Abrol said.</p>
            <h2>Media Contact</h2>
            <p>Deepanshu Shukla<br />Ruder Finn<br />+91 8888887892<br /><a href="mailto:deepanshu.shukla@ruderfinn.com" class="text-link">deepanshu.shukla@ruderfinn.com</a></p><br/>
            <a rel="noopener" href="https://www.thehindubusinessline.com/money-and-banking/galytix-launches-genai-credit-agent-solution-creditx-for-indian-banking-sector/article68145169.ece" target="_blank" class="btn-sm-primary text-color">Press release</a>
          </div>
        </div>  
      </div>    
    </div>

  </div>    
</template>


<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import BlogPost from "../utilities/gx-slideMedia.vue";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { textMaskFrom, textMaskTo } from "../utilities/util";
export default {
  components: {
    VueSlickCarousel,
    BlogPost,
  },
  data() {
    return {
      settings: {
        autoplay:true,
        autoplaySpeed:3000,
        slidesToShow: 4,        
        infinite: true,
        responsive: [
          // {
          //   breakpoint: 1441,
          //   settings: {
          //     slidesToShow: 2.3,
          //   },
          // },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 764,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      isVisible: false,
      openNewsPopup: '',
    };
  },
  mounted() {},
  methods: {
    visibilityChanged(isVisible) {
      this.isVisible = isVisible;
      if (this.isVisible) {
        this.onEnter();
      }
    },
    onEnter() {
      gsap.registerPlugin(ScrollTrigger);
      const container = document.getElementsByClassName('gx-section--media')[0]
      const title = container.querySelectorAll('h1')[0];
      const slickSlide = container.getElementsByClassName("slick-slide");
      const mediaSlider = container.getElementsByClassName("media-slider");
      const mediaBody = container.getElementsByClassName("body");

      gsap.fromTo(title, {
        '-webkit-mask-image': textMaskFrom,
        'mask-image': textMaskFrom,
      }, {
        immediateRender: true,
        "-webkit-mask-image": textMaskTo,
        'mask-image': textMaskTo,
        scrollTrigger: {
          trigger: container,
          start: "top 80%",
          end: "top -10%",
          scrub: true
        }
      });  
      gsap.fromTo(slickSlide, {
        opacity:0,      
      }, {
        opacity:1,
        stagger:window.innerWidth > 768 ? "0.1" : "0.1",
        // stagger:0.1,
        duration:window.innerWidth > 768 ? "0.2" : "2",
        // duration:0.2,      
        immediateRender: true,        
        scrollTrigger: {
          trigger: mediaSlider,
          // start:window.innerWidth > 768 ? "top 90%" : "top 90%",
          start: "top 90%",
          // end:window.innerWidth > 768 ? "center 70%" : "center 60%",
          end: "center 70%",
          scrub: true
        }
      }); 
      gsap.fromTo(container, {
        opacity:1,      
      }, {
        opacity:0,
        // stagger:0.1,:window.innerWidth > 768 ? "" : ""
        duration:0.2,       
        immediateRender: true,        
        scrollTrigger: {
          trigger: mediaBody,
          start: window.innerWidth > 768 ? "center 50%" : "center 30%",
          // start: "center 50%",
          end: window.innerWidth > 768 ? "bottom 10%" : "bottom -10%",
          // end: "bottom 10%",
          scrub: true
        }
      }); 
    },
    
    onBlogPostLearnMore: function (id) {
      const container = document.getElementsByTagName('body')[0];
      container.style.overflow = 'hidden';      
      if(id === 'news1')
      {
        this.$refs.news1.style.display  = 'block';
      }
      else if(id === 'news2')
      {
        this.$refs.news2.style.display = 'block';
      }
      else if(id === 'news12')
      {
        this.$refs.news12.style.display = 'block';
        this.openNewsPopup = id;
      }
      else if(id === 'news13')
      {
        this.$refs.news13.style.display = 'block';
        this.openNewsPopup = id;
      }
      else if(id === 'news14')
      {
        this.$refs.news14.style.display = 'block';
        this.openNewsPopup = id;
      }
      else if(id === 'news15')
      {
        this.$refs.news15.style.display = 'block';
        this.openNewsPopup = id;
      }
      else if(id === 'news16')
      {
        this.$refs.news16.style.display = 'block';
        this.openNewsPopup = id;
      }
      else {
        window.open(id, '_blank'); 
        container.style.overflow = 'visible';
      }

    },
    onPopupClose: function(id) {
      this.openNewsPopup = '';
      const container = document.getElementsByTagName('body')[0];
      container.style.overflow = 'visible';
      
      if(id === 'news1')
      {
        this.$refs.news1.style.display  = 'none'; 
      }
      else if(id === 'news2')
      {
        this.$refs.news2.style.display = 'none';
      }
      else if(id === 'news12')
      {
        this.$refs.news12.style.display = 'none';
        const iframe = document.getElementById('videoIframe12');        
        iframe.contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*') 
      }
      else if(id === 'news13')
      {
        this.$refs.news13.style.display = 'none';   
        const iframe = document.getElementById('videoIframe13');        
        iframe.contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*') 
      }
      else if(id === 'news14')
      {
        this.$refs.news14.style.display = 'none';   
        const iframe = document.getElementById('videoIframe14');        
        iframe.contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*') 
      }
      else if(id === 'news15')
      {
        this.$refs.news15.style.display = 'none';   
        const iframe = document.getElementById('videoIframe15');        
        iframe.contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*') 
      }
      else if(id === 'news16')
      {
        this.$refs.news16.style.display = 'none';   
        const iframe = document.getElementById('videoIframe16');        
        iframe.contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*') 
      }
    }
  },
};
</script>


<style lang="scss">
  
.media-slider .slick-track {
  padding: 15px;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  .slick-slide {
    margin-left: 15px;
    border: 1px solid #1e1e1e;
    height: auto;
    display: flex;
    transition: box-shadow 0.3s ease-in-out;  
    &:hover {
      box-shadow: 0px 0px 17px rgb(0 109 216);
     transition: box-shadow 0.3s ease-in-out;
    }
  }
}
.gx-section {
  &.gx-section--media {
    height: auto;
    background-repeat: no-repeat;
    background-position: bottom left;
    // background-color: #161617;
    color: $white;
    display: flex;
    flex-direction: column;
    padding: 50px 0;
    padding-left: 20px;
    padding-right: 20px;
    overflow: hidden;
    // @media screen and (max-width: ($mobile-breakpoint)) {
    //     padding-right: 20px;
    //   }
    .container {
      max-width: 1440px;
      margin: 0 auto;
      width: 100%;
      color: $white;      
      @include breakpoint($min-width: 1239px) {
        max-width: 1440px;
        margin: 0 auto;
        padding: 0;
      }
      h1 {
        margin-bottom: 4rem;
        display: inline-block;
        @include breakpoint($large) {
          margin-bottom: 5rem;
        }        
      }           

      .slick-slider {
        margin-bottom: 30rem;  
        @media screen and (max-width: ($mobile-breakpoint)) {
          margin-bottom: 20rem;
        }
        @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
          margin-bottom: 2rem; 
        }
      }
    }
  }
}

.text-color {
  color: #a6a6a6;
}
/* --- popup --- */
.gx-popup {
  background: rgba(0,0,0, 0.8);
  padding: 5% 0;
  position: fixed;
  overflow: auto;
  height: 100vh;
  width: 100%;   
  // left: 0;
  // right: 0;
  top: 0;
  bottom: 0;
  // display: block;
  // z-index: 11000;
  .gx-popup-content-wrap {
    // margin-left: auto;
    // margin-right: auto;
    // width: 980px;
    // padding: 0 80px;
    
    .popup-content {
      background-color: #161616;   
      .popupClose {
        border: 2px solid #f5f5f7;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;        
        position: fixed;        
        cursor: pointer;
        margin: 20px;
        font-weight: 500;
        color: #f5f5f7;
      }   
      .banner {
        height: 280px;
        background-position: center;
        background-size: cover;
      }
      .banner-news-1{
        background-image: url("../../assets/imgs/news/news1.jpg");
      }
      .banner-news-2{
        background-image: url("../../assets/imgs/news/news2.jpg");
      }
      .banner-news-14{
        background-image: url("../../assets/imgs/news/news14.jpg");
      }
      
      .banner-news-15.banner{
        height: 430px;
        @media screen and (max-width: ($mobile-breakpoint)) {
          height: 385px;
        }

      }
      .banner-news-16.banner {
        height: 430px;
        // @media screen and (max-width: ($mobile-breakpoint)) {
        //   height: auto;
        // }

      }
      .banner-news-15 {
        background-image: url("../../assets/imgs/news/news15.jpg");        
      }
      .banner-news-16 {
        background-image: url("../../assets/imgs/news/news16.jpg");        
      }

      .popup-body {
        padding: 50px 8.33333% 80px;
        hr {
          margin:45px 0;
          background-image: linear-gradient(90deg,#006dd8,#8fc7ff);
          border: none;
          height: 2px;
        }
        h1 {
          font-family: $primary-font;          
          font-size: 4.8rem;
          line-height: 1.2;
          // line-height: 1.08349;
          font-weight: 600;
          letter-spacing: -.003em;
          margin-bottom: 22px;          
        }
        h2 {
          font-family: $primary-font;
          margin-top: 22px;
          font-size: 32px;
          line-height: 1.3;
          font-weight: 600;
          letter-spacing: 0.004em;
          color: transparent;
          background-image: linear-gradient(to right, #006DD8, #8fc7ff);
          background-clip: text;
          -webkit-background-clip: text;    
        }
        p {
          font-size: 21px;
          line-height: 1.3;
          font-weight: 600;
          letter-spacing: .011em;
          color: #a6a6a6;
          margin-top: 22px;
          word-wrap: break-word;
        }
        a {
          color: #006dd8;
        }
        .news-companies-logo {  
            display: flex;
            align-items: center; 
            height: 78px;
            margin-top: 20px;
            margin-bottom: 40px;
        }
        .news-companies-logo .newsGalytixLogo,
        .news-companies-logo .newsSocGenLogo {
            width: 100%
        }
        .news-companies-logo .company-logo + .company-logo {
            border-left: 1px solid #8b939b;
        }
        .news-companies-logo .company-logo {
            display: flex;
            height:100%;
            align-items: center;
            padding: 0 10px;
        }


      }
    }    
  }
}

.media-slider {
  .slick-prev,
  .slick-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-indent: -999px;
    width: 40px;
    height: 40px;
    background-size: 100% 100%;
    overflow:hidden;
  }
  .slick-prev {
    right: 101%;  
    background-image: url("../../assets/imgs/prev-arrow.png");
    
  }
  .slick-next {
    left: 101%;
    background-image: url("../../assets/imgs/next-arrow.png");
  }
  .slick-dots {
    display: flex!important;
    flex-direction: row;
    color: #fff;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }
  .slick-dots li {
    display: inline-block;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    text-indent: -999px;
    margin: 3px;
    background-color: #f5f5f7;
    cursor: pointer;
  }
  .slick-dots li.slick-active {
    background-color: #006dd8;
  }
}

@media screen and (max-width: ($mobile-breakpoint)) {
  .media-slider .slick-track {
    padding: 0 0 10px;
    .slick-slide {
      margin-left:0px;
    }
  }
  .media-slider {
    .slick-prev,
    .slick-next {
      display: none!important; 
    }      
  }
}
@media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
  .media-slider .slick-track {
    padding: 0 0 10px;
    // .slick-slide {
    //   margin-left:0px;
    // }
  }
  .media-slider {
    .slick-prev,
    .slick-next {
      display: none!important; 
    }      
  }
}

@media only screen 
  and (min-device-width: 800px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5)
  and (orientation: landscape) {  
  .media-slider {
    .slick-prev,
    .slick-next {
      display: none!important; 
    }      
  }  
}

@media only screen 
and (min-device-width: 820px) 
and (max-device-width: 1366px) 
and (-webkit-min-device-pixel-ratio: 1.5)
and (orientation: portrait) {
  .media-slider {
    .slick-prev,
    .slick-next {
      display: none!important; 
    }      
  }  
}



</style>
 