<template>
  <section class="slide-container-media">
    <div class="slide-media">
      <div class="thumbMedia" :style="`background-image: url(${publicPath}/static/assets/imgs/news/${source})`"></div>
      <div class="header">
        <h6 class="smallHeading">{{ smallHeading }}</h6>        
      </div>
      <div class="body">
        <h6 class="heading">{{ heading }}</h6>
        <div class="media-content">{{ content }}</div>
        <a v-on:click="learnMore" class="link btn-sm-primary">Learn More</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    emits: ['learnMore'],
    props: {
    smallHeading: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    source: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      publicPath: process.env.NODE_ENV === "production" ? "/gx/" : "",
    };
  },
  methods: {
    learnMore: function() {
      this.$emit('learnMore', this.link);
    }
  }  
};
</script>

<style lang="scss">

.slide-container-media {
  position: relative;
  padding: 15px;
}
.slide-media {

  .thumbMedia {
    height: 190px;
    background-size:auto 100%;
    background-repeat: no-repeat;
    background-position: center center;
  // background-color: transparent;
    transition: all 0.5s ease;
    // @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
    //   float: left;
    //   width: 200px;
    //   margin-right: 15px;
    // }
  }
  &:hover {
    .thumbMedia {      
      background-size: auto 120%;
      transition: all 0.5s ease;
    }
  }
  .header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-items: center;
    z-index: 2;
    position: relative;
    .smallHeading {
      font-family: $secondary-font;
      font-weight: 500;
      color: $white;
      padding: 15px 0px;
      font-size: 1.4rem;
      // margin-bottom:1rem ;
    }    
  }
  .body {
    // width: 75%;
    position: relative;
    z-index: 2;
    .heading {
      color: $white;
      font-family: $primary-font;
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 3.1rem;
      margin-bottom: 3rem;
      @media screen and (max-width: ($mobile-breakpoint)) {
        font-size: 2.4vh;
        line-height: 3.1vh;
      }
      @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
        font-size: 2.4vw;
        line-height: 3.1vw;
      }
    }
    .media-content {
      color: #a6a6a6;
      font-family: $primary-font;
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 3.1rem;
      margin-bottom: 3rem;
      @media screen and (max-width: ($mobile-breakpoint)) {
        font-size: 2.4vh;
        line-height: 3.1vh;
      }
      @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
        font-size: 2.4vw;
        line-height: 3.1vw;
      }
    }    
  }
}
</style>
