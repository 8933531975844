<template>
  <div class="gx-section gx-section--ipads1">
    <div class="container">
      <div class="left-text">
        <h1 id="leftTitle"><span class="text-color-3">Signal Detection </span><br>
          Early Warning Signals</h1>
        <!-- <h1 id="leftTitle">Early Warning Signals and Trend Detections</h1> -->
        <div id="leftCopy" class="copy">
          <!-- <h4 class="text-color-2">Analytics delivering signal not noise with sources that work in the future not just the past. Constantly fine-tuned to find signals relevant in the current moment</h4> -->
          <h4 class="text-color-4">12 Risk signal categories tracked</h4>
          <div class="gradient-line"></div>          
          <h4 class="text-color-4">40% Additional predictive power by sector</h4>
          <div class="gradient-line"></div>       
          <h4 class="text-color-4">240+ Sources of data ingested</h4>
        </div>
      </div>
    </div>
    <div class="ipad">
      <img src="../../assets/imgs/gx-tablet.svg" alt="GX tablet" />
      <video id="videoOne" autoplay playsinline muted="muted" ref="video" loop>
        <source src="../../assets/videos/section2.mp4" />
      </video>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { textMaskFrom, textMaskTo, } from "../utilities/util";

export default {
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    animations() {
      gsap.registerPlugin(ScrollTrigger);
      ScrollTrigger.refresh();

      const container = document.querySelector(".gx-section--ipads1");
      const title = container.querySelector("#leftTitle");
      // const copy = container.querySelector("#leftCopy");
      const ipad = container.querySelector(".ipad");
      const video = ipad.querySelector("#videoOne");

      video.play();
      
      gsap.fromTo(".left-text", {
        opacity:0,
        '-webkit-mask-image': textMaskFrom,
        'mask-image': textMaskFrom,
      }, {
        opacity:1,
        immediateRender: true,
        "-webkit-mask-image": textMaskTo,
        'mask-image': textMaskTo,
        scrollTrigger: {
          trigger: title,
          start:window.innerWidth > 768 ? "top 100%" : "top 80%",
          // start: "top 100%",
          end:window.innerWidth > 768 ? "bottom 10%" : "bottom 10%",
          // end: "bottom 10%",
          scrub: true
        }
      })
      gsap.fromTo(ipad, {
        opacity: 0,
        transform: "translateX(100vw)",
      }, {
        transform: window.innerWidth > 768 ? "translateX(30vw)" : "translateX(0)",
        opacity: 1,
        duration: 10,
        immediateRender: false,
        scrollTrigger: {
          trigger: container,
          start: "top 80%",
          end: "top 40%",
          scrub: true
        },
      })      
      gsap.fromTo(video, {
        opacity: 0,
      },{
        opacity: 1,
        duration: 0.5,
        immediateRender: false,
        scrollTrigger: {
          trigger: container,
          start: "top 80%",
          end: "top 70%",
          scrub: true,
        },
      })
      gsap.fromTo(".gx-section--ipads1", {
        opacity: 1,
        duration: 1,
        immediateRender: false,
      }, {
        opacity: 0,
        duration: 10,
        immediateRender: false,
        scrollTrigger: {
          trigger: container,
          start: "center 30%",
          end: "center -20%",
          scrub: true
        },
      })
    },
  },
  mounted() {
    this.animations();
  },
};
</script>


<style lang="scss">
.gx-section--ipads1 {
  min-height: 100vh;
  height: 100vh;
  background: $black;
  background-repeat: no-repeat;
  background-position: bottom left;
  color: $white;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: visible;
  position: relative; 
  .container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    display: flex;

    @media screen and (max-width: ($mobile-breakpoint)) {
      height: 100vh;
    }

    .left-text {
      height: 100vh;
      width: 50%;
      color: white;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-right: 25px;
      @media screen and (max-width: ($mobile-breakpoint)) {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        padding: 0px;
        justify-content: space-between;
        width: 100%;
        height: 100%;        
      }

      h1 {
        margin-bottom: 4rem;
        @include breakpoint($large) {
          margin-bottom: 5rem;
        }
        @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
          margin-bottom: 2rem;
        }
      }

      h4 {
        margin-bottom: 2.5rem;
        font-weight: bold;    
        @media screen and (max-width: ($mobile-breakpoint)) {
          font-weight: 500;
        }
        @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
          margin-bottom: 2vw;
        }
      }

      #leftTitle,
      #leftCopy {
        opacity: 1;
      }

    }
  }

  .ipad {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: translate(100vw);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    opacity: 0;

    @media screen and (max-width: ($mobile-breakpoint)) {
      top: -145px;
    }
    @media screen and (max-width: 448px) { //390
      top: -270px;
    }

    img,
    video {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    img {
      box-shadow: 0px 0px 79px 29px rgba(0, 109, 216, 0.5);
      border-radius: 37px;
      width: 55%;

      @media screen and (max-width: ($mobile-breakpoint)) {
        width: 80%;
        border-radius: 10px;
      }
    }

    video {
      width: 50%;
      opacity: 0;

      @media screen and (max-width: ($mobile-breakpoint)) {
        width: 75%;
      }
    }
  }
}

@media only screen 
  and (min-device-width: 800px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5)
  and (orientation: landscape) { 
  .gx-section--ipads1 .container .left-text h1 {
    margin-bottom: 3rem;
  }
}
</style>
 