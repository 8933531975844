<template>
  <div class="gx-section gx-section--two">
    <div class="gx-section-wrap">
      <div id="gx-section-two-title">
        <h1 class="confidenceText"><span class="text-color-3">Discover & <br class="show-xs"> Ingest Data</span> <br>Internal & External Sources</h1>
      </div>
      <div class="videoAnimation" id="gx-section-two-video">
        <img src="../../assets/imgs/gx-tablet.svg" alt="tablet" />
        <img class="gx-circle-chart gxChart-1" src="../../assets/imgs/gx-tablet-section2-screen1.jpg" alt="GX chart" />
        <img class="gx-circle-chart gxTable-1" id="gx-section-two-table" src="../../assets/imgs/gx-tablet-section2-screen2.jpg" alt="GX table" />
      </div>
      <h4 class="textInternal text-color-4">Combine operational, transactional, financial and <br class="show-lg"> alternative data connected in a single ontology</h4>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { textMaskFrom, textMaskTo, imageMaskFrom, imageMaskTo} from "../utilities/util";

export default {
  methods: {
    animation() {
      gsap.registerPlugin(ScrollTrigger);
      const gxSectionTwo = document.querySelector(".gx-section--two");

      ScrollTrigger.clearMatchMedia();
      ScrollTrigger.refresh();

      let tl = gsap.timeline({
        defaults: { duration: 10 }
      });

      tl.fromTo("#gx-section-two-title", {
        '-webkit-mask-image': textMaskFrom,
        'mask-image': textMaskFrom,
      }, {
        immediateRender: true,
        "-webkit-mask-image": textMaskTo,
        'mask-image': textMaskTo,
        scrollTrigger: {
          trigger: "#gx-section-two-title",
          start: "top 60%",
          end: "bottom 40%",
          scrub: true,
          // markers:true,
        }
      });
      
      tl.fromTo("#gx-section-two-video", {        
        '-webkit-mask-image': imageMaskFrom,
        'mask-image': imageMaskFrom,
        immediateRender: true,
      }, {     
        y:window.innerWidth > 768 ? "-30vh" : "-20vh",
        '-webkit-mask-image': imageMaskTo,
        'mask-image': imageMaskTo,
        immediateRender: true,
        scrollTrigger: {
          trigger: gxSectionTwo,
          start: 'top 70%',
          end: 'bottom 60%',
          scrub: true,
        }
      });      
      tl.fromTo('#gx-section-two-table', {
        opacity: 0,
      },{
        opacity: 1,
        immediateRender: true,
        scrollTrigger: {
          trigger: gxSectionTwo,
          start: 'top 20%',
          end: 'center 60%',
          scrub: true
        }
      });
      tl.fromTo('#gx-section-two-video', {
        opacity: 1,
      },{
        opacity: 0,
        immediateRender: true,
        scrollTrigger: {
          trigger: gxSectionTwo,
          start: 'bottom 90%',
          end: 'bottom 20%',
          scrub: true
        }
      });  
      tl.fromTo('.textInternal', {
        '-webkit-mask-image':window.innerWidth > 768 ? "textMaskFrom" : "",
        'mask-image':window.innerWidth > 768 ? "textMaskFrom" : "",
        // '-webkit-mask-image': textMaskFrom,
        // 'mask-image': textMaskFrom,
      }, {
        y:window.innerWidth > 768 ? "-35vh" : "-20vh",        
        immediateRender: true,
        "-webkit-mask-image":window.innerWidth > 768 ? "textMaskTo" : "",
        'mask-image':window.innerWidth > 768 ? "textMaskTo" : "",
        // "-webkit-mask-image": textMaskTo,
        // 'mask-image': textMaskTo,
        scrollTrigger: {
          trigger: gxSectionTwo,
          start:window.innerWidth > 768 ? "center 60%" : "center 60%",
          // start: "center 60%",
          end:window.innerWidth > 768 ? "bottom 50%" : "bottom 80%",
          // end: "bottom 50%",
          scrub: true,     
          // markers: true    
        }
      });
      tl.fromTo('.textInternal', {
        opacity: 1,
      },{
        opacity: 0,
        immediateRender: true,
        scrollTrigger: {
          trigger: gxSectionTwo,
          start:window.innerWidth > 768 ? 'bottom 80%' : 'bottom 70%',          
          end:window.innerWidth > 768 ? 'bottom 40%' : 'bottom 20%',          
          scrub: true
        }
      });  
    },
  },
  mounted() {
    this.animation();
  },
};
</script>

<style lang="scss">
.gxTable-1 {
  opacity: 0;
}
.gx-section--two {
  text-align: center;
  background: radial-gradient(at -8% -3%, rgba(3, 77, 185, 0.4) 5px, transparent 70%);
  background-repeat: no-repeat;
  background-position: right bottom;
  color: $white;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  overflow-x: hidden;
  .gx-section-wrap {
    // height: 100vh;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: ($mobile-breakpoint)) {
      text-align: left;
    }
  }
  #gx-section-two-title {
    margin: 0 auto;
    @media screen and (max-width: ($mobile-breakpoint)) {
      padding-left: 20px;
      padding-right: 20px;
    }  
    h1 {
      padding-bottom: 20vh;
      // @media screen and (max-width: ($mobile-breakpoint)) {
      //   padding-bottom: 16vh;
      // }
    }   
  }
  h4.text-color-4 {
    font-weight: 600;
    color: transparent;
    background-image: linear-gradient(to right, #006DD8, #66b3ff);
    -webkit-background-clip:text;
    background-clip:text;
    margin: 0 auto;      
    @media screen and (max-width: ($mobile-breakpoint)) {
      font-weight: 500;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .videoAnimation {
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: visible;
    background-size: 100%;
    margin: auto;
    max-width: 100vw;  
    height: 80vh;      

    @media screen and (max-width: ($mobile-breakpoint)) {
      height: 35vh;
      min-height: 270px;
    }
    @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {      
      min-height: 270px;      
    }
    @include breakpoint($medium) {      
      max-width: 80vw;
      width: 88vw;
      height: 80vh;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 100%;
      box-shadow: 0px 0px 12px 10px rgba(0, 109, 216, 0.5);
      border-radius: 37px;
      @media screen and (max-width: ($mobile-breakpoint)) {
        width: 80%;
        box-shadow: 0px 0px 38px 23px rgb(0 109 216 / 32%);   
        border-radius: 10px;       
      }
      @include breakpoint($medium) {
        width: 70%;
      }      
      @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {      
        width: 60%;  
        border-radius: 20px;   
      } 
    }

    .gx-circle-chart {
      width: 95%;
      box-shadow: none;
      @media screen and (max-width: ($mobile-breakpoint)) {
        width: 75%;
      } 
      @include breakpoint($medium) {
        width: 65%;
      }
      @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {      
        width: 55%;     
      }      
    }
  }
}

.gx-section--two.gx-section {
  @media screen and (max-width: ($mobile-breakpoint)) {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen 
and (min-device-width: 820px) 
and (max-device-width: 1366px) 
and (-webkit-min-device-pixel-ratio: 1.5)
and (orientation: portrait) { 
  .gx-section--two .videoAnimation .gx-circle-chart {
    width: 85%;
  }
  .gx-section--two .videoAnimation {
    max-width: 90vw;
    width: 90vw;
    height: 50vh;
    img { 
      width: 90%;
      border-radius: 30px;
    }
  }
}


</style>

