<template>
  <div id="app">
    <gxHeader v-if="isLoaded" />
    <gxLoader v-show="!isLoaded" />
    <gxContent v-if="isLoaded" :skipintrovideo="skipIntroVideo" />
    <div id="analytics-consent-banner" style="display:none;">
      Do you consent to have cookies stored on our device and sent to our servers for analytic purposes? <a class="cookie-policy-banner-link" target="_blank" href="/gx/Galytix%20Cookie%20policy.pdf">(Cookie policy)</a><br class="show-on-mobile">
      &nbsp;<button class="analytics-button analytics-reject mob-margin-top-md" onclick="rejectAnalyticsCookies()">Reject</button>&nbsp;<button class="analytics-button analytics-accept mob-margin-top-md" onclick="acceptAnalyticsCookies()">Accept</button>
    </div>
  </div>
</template>

<script>
import gxHeader from './components/common/header.vue'
import gxLoader from './components/common/loader.vue'
import gxContent from './components/sections/gxContent.vue'

export default {
  name: 'App',
  components: {
    gxHeader,
    gxLoader,
    gxContent
  },
  data () {
    return {
      isLoaded : false,
      skipIntroVideo: false,
    }
  },
  mounted () {
    this.axios.get(require(`@/assets/videos/home.mp4`)).then(() => {
      document.getElementsByClassName('gx-loader')[0].classList.add('complete')
      setTimeout(() => {
        this.isLoaded = true
      }, 100);
    });
    setTimeout(() => {
      if (!this.isLoaded) {
        this.isLoaded = true;
        this.skipIntroVideo = true;
      }
    }, 5000);  //Edit this value to increase or decrease the time we give the intro video to load
  }
}
</script>

<style lang="scss">
@import './scss/settings.scss';
@import './scss/base.scss';

body {
  background: black;
  scroll-behavior: smooth;
}

.scrollLocked {
  overflow: hidden;
}

</style>
