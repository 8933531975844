<template>
  <div class="gx-section gx-section--client" v-observe-visibility="visibilityChanged">
    <div class="container-client">
      <div class="container">
        <h1><span class="text-color-3">Client Impact</span> <br>Implementation Cases</h1>      
        <VueSlickCarousel :arrows="true" :dots="true" v-bind="settings" class="client-slider">
          <BlogPost          
            :heading="`Building a next-gen Early Warning system, propelled by AI-driven data engineering`"
            :content="`A major Swiss financial institution was struggling with their ability to detect corporate credit risk deterioration early enough to act and protect their ROE...`"
            :source="`client-1.jpg`"
            :link="`client1`"
            v-on:learnMore="onBlogPostLearnMore"
          />
          <BlogPost          
            :heading="`GX as a secure Data Operating System: transform credit decisioning at a bank using AI `"
            :content="`A systematically important global bank was looking to transform credit decisioning across the entire credit chain within their corporate banking division...`"          
            :source="`client-2.jpg`"
            :link="`client2`"
            v-on:learnMore="onBlogPostLearnMore"
          />
          <BlogPost          
            :heading="`Leveraging AI and external data to improve portfolio steering at a leading reinsurer`"
            :content="`One of the largest reinsurers in the world was looking to digitise their processes of producing market outlooks for insurance segments and granular competitor benchmarking...`"          
            :source="`client-3.jpg`"
            :link="`client3`"
            v-on:learnMore="onBlogPostLearnMore"
          />
          <BlogPost          
            :heading="`Providing a Data Operating System for a health insurer’s expansion`"
            :content="`A leading European insurer was expanding in the fast-growing health insurance business but struggling to generate relevant insights from the huge explosion in unstructured and structured data from customers and third parties...`"          
            :source="`client-4.jpg`"
            :link="`client4`"
            v-on:learnMore="onBlogPostLearnMore"
          />        
        </VueSlickCarousel>        
      </div>
    </div>  
    
    <div id="client1" class="gx-popup" style="display: none;" ref="client1">
      <div class="gx-popup-content-wrap">
        <div class="client-content popup-content">
          <div class="popupClose" v-on:click="onPopupClose('client1')">X</div>
          <div class="banner banner-client-1"></div>
          <div class="popup-body">
            <h1>Building a next-gen Early Warning system, propelled by AI-driven data engineering</h1>
            <p>A major Swiss financial institution was struggling with their ability to detect corporate credit risk deterioration early enough to act and protect their ROE. They were looking for a technology firm to build a scalable solution for them. But they understood this wasn’t merely a software challenge but would need domain expertise of corporate credit risk signals. That is when they decided to partner with Galytix. One of the key success factors was that data had to be 100% accurate, reliable, and consistent for it to be used by analysts as part of their credit and risk monitoring processing.</p>
            <hr />
            <div class="block-section">
              <div class="block-title col-6">
                <span>Key Facts</span>
              </div>
              <div class="block-content col-6">
                <ul>
                  <li>
                    <div class="key-text">Tailored signals tracked</div>
                    <div class="key-value"><span class="value">240</span> Data sources</div>
                    
                  </li>
                  <li>
                    <div class="key-text">Additional predictive insights based on sectors and obligors</div>
                    <div class="key-value"><span class="value">c.40%</span></div>
                    
                  </li>
                  <li>
                    <div class="key-text">Risk factor related changes tracked</div>
                    <div class="key-value"><span class="value">c.95%</span></div>
                    
                  </li>
                  <li>
                    <div class="key-text">Subsidiaries tracked on average for each counterparty, is more than</div>
                    <div class="key-value"><span class="value">300</span></div>
                    
                  </li>
                  <li>
                    <div class="key-text">Management changes tracked</div>
                    <div class="key-value"><span class="value">100%</span></div>
                    
                  </li>
                </ul>                
              </div>
            </div>
            <hr />
            <div class="block-section">
              <div class="block-title col-6">
                <span>Adding new data and discovering new alternative data sources </span>
              </div>
              <div class="block-content col-6">
                The financial institution had relied heavily on internal data and had not taken advantage of the huge amount of external data available. The few external data points came from late indicators such as credit agency ratings. This does not give much time for our client, to adjust their balance sheet exposures. Galytix provided a wide range of new external indicators in four clearly defined categories: financial strength, regulation impact, company networks and management changes. 
              </div>
            </div>
            <hr />
            <div class="block-section">
              <div class="block-title col-6">
                <span>Ensuring data is relevant </span>
              </div>
              <div class="block-content col-6">
                At the start of this journey our client raised concerns about how time-consuming adding new data would be in terms of their team having to manually sort through any results. They had seen other AI-driven platforms process massive amounts of data but with a high degree of false positives. We provided them with a 4-stage neural network process which started with entity recognition, then a hard match of specific key words related to early warning signals, a third stage which incorporated relevant semantics and a final yes/no signal. All the way along this pipeline there was a rigid process to ensure this data was from high quality sources.    
              </div>
            </div>
            <hr />
            <div class="block-section">
              <div class="block-title col-6">
                <span>Solving the data engineering challenge  </span>
              </div>
              <div class="block-content col-6">
                Our client had a siloed data architecture with multiple systems. In only ninety days we were able to offer a solution that could be fully deployed in a production environment. It was a cloud-based data ecosystem that sat on top of their existing systems. An end-to-end approach from data discovery and ingestion through to transformation and analysis gave our client the flexibility they desired in terms of fine-tuning data types and sources and ensuring data lineage and auditability.  Our proprietary algorithms were able to discover, ingest and categorise a wide variety of structured and unstructured data at scale and speed. 
              </div>
            </div>
            <hr />
            <div class="block-section">
              <div class="block-title col-6">
                <span>Bringing together actionable data in one place </span>
              </div>
              <div class="block-content col-6">
                Our scalable ontology allowed us to offer one data ecosystem incorporating a diverse range of data sets. We were able to bring together both financial and non-financial metrics in one easy to use interface. The radar above illustrates how we segmented signals by risk category. The heat map illustrates how we tracked data sources and ensured data was from the highest quality sources. We back-tested data across a huge number of counterparties to ensure that our sentiment analysis scores were correct. These quantified results in a clearly defined Early Warning framework make it easy to integrate these in credit models that could drive client ratings.
              </div>
            </div>
            
            <!-- <p>Galytix, a high growth AI driven FinTech firm announces the successful completion of a fundraising. The firm deploys integrated data ecosystems for global financial players delivering Early Warning Signals (EWS) and advanced predictive analytics. Galytix’s solutions have consistently outperformed peers in speed of delivery and accuracy across multiple internal and external data sets. The capital raised will be used to support its growth ambitions in Europe responding to growing demand in the banking, capital markets, asset management and insurance sectors. The funds raised will be deployed in client focused R&amp;D and allow Galytix to expand its European operations in Switzerland, the Czech Republic and soon its additional R&amp;D base in Luxembourg.</p> -->
          </div>
        </div>  
      </div>    
    </div>

    <div id="client2" class="gx-popup" style="display: none;" ref="client2">
      <div class="gx-popup-content-wrap">
        <div class="client-content popup-content">
          <div class="popupClose" v-on:click="onPopupClose('client2')">X</div>
          <div class="banner banner-client-2"></div>
          <div class="popup-body">
            <h1>GX as a secure Data Operating System: transform credit decisioning at a bank using AI </h1>
            <p>A systematically important global bank was looking to transform credit decisioning across the entire credit chain within their corporate banking division. Credit analysts had been spending 70% of their time on data and only 30% on analysis. With increasing amounts of data available and new requirements such as ESG on the horizon they knew that they would have to introduce technology into this process. They realised that they needed an external solution and started working with Galytix in 2020, expanding the relationship further in 2022.</p>
            <hr />
            <div class="block-section">
              <div class="block-title col-6">
                <span>Key Facts</span>
              </div>
              <div class="block-content col-6">
                <ul>
                  <li>
                    <div class="key-text">Data accuracy guaranteed across structured and unstructured sources</div>
                    <div class="key-value"><span class="value">100% </span></div>
                    
                  </li>
                  <li>
                    <div class="key-text">Automation in engineering of unstructured data</div>
                    <div class="key-value">Up to <span class="value"> 90%</span></div>
                    
                  </li>
                  <li>
                    <div class="key-text">Available at sector and sub segment level for analysis</div>
                    <div class="key-value"><span class="value">60% </span> more data</div>
                    
                  </li>
                  <li>
                    <div class="key-text">Time saving for credit analysts</div>
                    <div class="key-value"><span class="value">40%</span></div>
                    
                  </li>
                  <li>
                    <div class="key-text">Improvement in the speed of credit decision making</div>
                    <div class="key-value"><span class="value">30%</span></div>
                    
                  </li>
                </ul>                
              </div>
            </div>
            <hr />
            <div class="block-section">
              <div class="block-title col-6">
                <span>Automating manual processes</span>
              </div>
              <div class="block-content col-6">
                The bank had relied on its credit analysts to discover, ingest, and transform financial information on their borrowers. This was time consuming. Galytix’s combination of 20+ proprietary AI algorithms combined with open-source tools delivered a fully end-to-end algorithmic pipeline that was able to automatically discover and ingest hundreds of documents, transform these into the correct format required by the bank, connect them to the rest of the bank’s credit platform and spread this data as required across the bank.  
              </div>
            </div>
            <hr />
            <div class="block-section">
              <div class="block-title col-6">
                <span>A digitised data ecosystem  </span>
              </div>
              <div class="block-content col-6">
                Galytix was able to tailor its ontology to fit to the bank’s requirements and the full digitisation of the data in terms of meta tags, versioning, lineage, and other characteristics created a connected data ecosystem. As well as cost savings, there were significant other benefits for the bank. They reduced operational risks that existed in the previously analogue world, which lacked standardisation and had been reliant on excel. The digitisation of credit data also materially improved the bank’s ability to make queries or connect to other systems.
              </div>
            </div>
            <hr />
            <div class="block-section">
              <div class="block-title col-6">
                <span>A fully integrated solution </span>
              </div>
              <div class="block-content col-6">
                Our client finds that Galytix is unique in terms of being a complete data ecosystem rather than the piecemeal self-service model provided by traditional technology vendors. Galytix provides the bank with a cloud-based data operating system that sits on top of its legacy systems. A dedicated Galytix client-specific data engineering team working with a smaller equivalent team at the bank was crucial to ensure 100% data accuracy and alleviating any concerns about our AI algorithms being a black-box.
              </div>
            </div>
            <hr />
            <div class="block-section">
              <div class="block-title col-6">
                <span>A strategic partner with the speed of a start-up </span>
              </div>
              <div class="block-content col-6">
                The relationship between the leading bank and Galytix has been expanded further with credit risk related financial data for thousands of the bank's clients on the platform. This includes both internal and external data sources. All of this is in the bank’s own environment with data security a key hallmark of the relationship. The bank finds that Galytix provides both the accuracy and reliability they expect from a strategic partner with the speed of a start-up in terms of delivery on new use cases.  
              </div>
            </div>
            </div>
        </div>  
      </div>    
    </div>

    <div id="client3" class="gx-popup" style="display: none;" ref="client3">
      <div class="gx-popup-content-wrap">
        <div class="client-content popup-content">
          <div class="popupClose" v-on:click="onPopupClose('client3')">X</div>
          <div class="banner banner-client-3"></div>
          <div class="popup-body">
            <h1>Leveraging AI and external data to improve portfolio steering at a leading reinsurer</h1>
            <p>One of the largest reinsurers in the world was looking to digitise their processes of producing market outlooks for insurance segments and granular competitor benchmarking. The reinsurer had many best-in-class models leveraging its huge internal data corpus. But there was limited external data usage, with the firm unsure about how to industrialise the process of data discovery, ingestion, transformation, and analysis. The reinsurer was attracted to Galytix’s holistic approach bringing together data engineering, data science and AI software engineering. The amount and quality of available data had limited experts’ time spent on insights generation. </p>
            <hr />
            <div class="block-section">
              <div class="block-title col-6">
                <span>Key Facts</span>
              </div>
              <div class="block-content col-6">
                <ul>
                  <li>
                    <div class="key-text">Unique data sources:</div>
                    <div class="key-value">
                      <span class="value">11 </span>Pre GX<br>                      
                      <span class="value"> 125 </span>Post GX</div>                    
                    <!-- <div class="key-value"><span class="value">11 </span> Pre Galytix, <span class="value"> 125 </span>Post Galytix</div>                     -->
                  </li>
                  <li>
                    <div class="key-text">Unique indicators:</div>
                    <div class="key-value">
                      <span class="value">17 </span>Pre GX<br>
                      <span class="value"> 3,000 </span>Post GX
                    </div>
                    
                  </li>
                  <li>
                    <div class="key-text">% of non-economic indicators: </div>
                    <div class="key-value">
                      <span class="value">5% </span> Pre GX<br>               
                      <span class="value"> 20% </span>Post GX
                    </div>
                    
                  </li>
                  <li>
                    <div class="key-text">Time spent on data discovery in forward looking view: </div>
                    <div class="key-value">
                      <span class="value">80% </span> Pre GX <br>
                      <span class="value"> 20% </span>Post GX
                    </div>
                    <!-- <div class="key-value"><span class="value">80% </span> Pre Galytix, <span class="value"> 20% </span>Post Galytix</div> -->
                    
                  </li>                 
                </ul>                
              </div>
            </div>
            <hr />
            <div class="block-section">
              <div class="block-title col-6">
                <span>Discovering and incorporating external and alternative data </span>
              </div>
              <div class="block-content col-6">
                Our client had a treasure trove of internal data sets built up over the years, given their strong market presence. However, their use of the fast-growing area of external, and often unstructured data was limited. Following the implementation of the GX platform, the number of data sources increased more than tenfold and the number of unique indicators even more dramatically. An increasing proportion of this was non-economic and alternative data sources. Galytix’s AI algorithms accurately and quickly discovered and ingested this massive amount of data, which included over 40% of documents that came in unstructured formats.
              </div>
            </div>
            <hr />
            <div class="block-section">
              <div class="block-title col-6">
                <span>Digitising the data  </span>
              </div>
              <div class="block-content col-6">
                Several data engineering challenges were holding the reinsurer back. Firstly, there was no consolidated, easily accessible, and automatically updated data repository. Second, the repeatability and continuity of forward-looking view analysis hinged on specific individuals’ documentation. Galytix was able to digitise the tracking of insurance fundamentals and model-based forecasting to improve market outlook accuracy as well as establish a timely, reliable data corpus that can be leveraged for other business use cases.
              </div>
            </div>
            <hr />
            <div class="block-section">
              <div class="block-title col-6">
                <span>Improving modelling capabilities  </span>
              </div>
              <div class="block-content col-6">
                The reinsurer has deep-domain expertise and a world-class modelling capability internally but in many areas these models were based solely on internal data. In areas like motor and liability insurance, Galytix’s data scientists were able to leverage the newly added external and digitised datasets to build dozens of multivariate deep learning and statistical forecasting models. These forecasted exposure, loss frequency and loss severity.
              </div>
            </div>
            <hr />
            <div class="block-section">
              <div class="block-title col-6">
                <span>Combining machine and human intelligence </span>
              </div>
              <div class="block-content col-6">
                Galytix’s predictive models were built, as tools to support the deep-domain expertise of the in-house insurance underwriting and portfolio steering team. The GX platform market outlook interface enabled expert users to access raw and curated data and overlay forecasts with expert views. This combination of machines and humans extends all the way from the data engineering process, where Galytix provided the client with a dedicated team to help fine-tune algorithms and ensure 100% data accuracy.
              </div>
            </div>            
          </div>
        </div>  
      </div>    
    </div>

    <div id="client4" class="gx-popup" style="display: none;" ref="client4">
      <div class="gx-popup-content-wrap">
        <div class="client-content popup-content">
          <div class="popupClose" v-on:click="onPopupClose('client4')">X</div>
          <div class="banner banner-client-4"></div>
          <div class="popup-body">
            <h1>Providing a Data Operating System for a health insurer’s expansion</h1>
            <p>A leading European insurer was expanding in the fast-growing health insurance business but struggling to generate relevant insights from the huge explosion in unstructured and structured data from customers and third parties. Core to its growth strategy was leveraging external partnerships. But, this came with an operational cost. Underwriting and Finance experts spent 80% of their time processing rather than analyzing data. The insurer was attracted to Galytix’s ability to provide not just self-service tools but a fully integrated data ecosystem. This allowed the insurer to reduce costs, reduce operational risk and improve the speed and quality of decision-making and hence drive premium growth. </p>
            <hr />
            <div class="block-section">
              <div class="block-title col-6">
                <span>Key Facts</span>
              </div>
              <div class="block-content col-6">
                <ul>
                  <li>
                    <div class="key-text">Turnaround time for data processing: </div>
                    <div class="key-value">
                      <span class="value">10 </span>Days pre GX<br>
                      <span class="value"> 48 </span>Hours post GX
                    </div>                    
                    <!-- <div class="key-value"><span class="value">10  </span>days Pre Galytix, <span class="value"> 48  </span>hours Post Galytix</div>                     -->
                  </li>
                  <li>
                    <div class="key-text">Frequency of new data for insights:</div>                  
                    <div class="key-value">
                      <span class="value">Monthly </span>Updates pre GX<br>
                      <span class="value"> Daily </span>Updates post GX
                    </div>
                    <!-- <div class="key-value"><span class="value">Monthly  </span>updates Pre Galytix, <span class="value"> Daily  </span>updates Post Galytix</div> -->
                    
                  </li>
                  <li>
                    <div class="key-text">Expert time spent on data cleansing vs. data analysis: </div>
                    <div class="key-value">
                      <span class="value">80:20 </span>pre GX <br>
                      <span class="value">0:100   </span>post GX
                    </div>
                    <!-- <div class="key-value"><span class="value">10-30%  </span> Pre Galytix, <span class="value"> 80-100%  </span>Post Galytix</div> -->
                    
                  </li>
                  <li>
                    <div class="key-text">Data leveraged in analysis: </div>
                    <div class="key-value">
                      <span class="value">10-30%  </span>pre GX <br>
                      <span class="value">80-100%  </span>post GX
                    </div>
                    
                  </li>                 
                </ul>                
              </div>
            </div>
            <hr />
            <div class="block-section">
              <div class="block-title col-6">
                <span>Automating external partner data </span>
              </div>
              <div class="block-content col-6">
                A growing trend in insurance is the use of managing general underwriters (MGU’s) and third-party administrators. Our client was focused on leveraging these relationships to scale their franchise. But hundreds of files from dozens of external partners were submitted monthly and each of these partners had developed their own processes. Our AI algorithms supported by a small data engineering team, standardised and automated all of this. Manual time-intensive data processing by our client was reduced materially. As anomalies were algorithmically detected and resolved, partners were provided statistics on the quality of the data they provided.
              </div>
            </div>
            <hr />
            <div class="block-section">
              <div class="block-title col-6">
                <span>Bringing together all data in one operating system  </span>
              </div>
              <div class="block-content col-6">
                In addition to external partners, Galytix built pipelines to our client’s core systems spanning its operational entities and its functional siloes. The insurer had started to adopt software tools across claims, policy administration and other areas but none of the data was connected. We brought together internal and external data from multiple systems and put it all in the same format so that our client had one continuously updating and reliable source of truth. Our enterprise-wide ontology and end-to-end data pipelines ensured consistency of definitions and the appropriate firm wide governance in terms of auditability, versioning and access rights.
              </div>
            </div>
            <hr />
            <div class="block-section">
              <div class="block-title col-6">
                <span>Different from traditional software vendors </span>
              </div>
              <div class="block-content col-6">
                The first question our client asked was: “how are you different from all the cloud vendors?” We explained how we provided a fully integrated operating system that discovers relevant external data, ingests even the most unstructured data, provides tailored analytics and ensures 100% data accuracy owing to our human supervision of AI. After deploying Galytix, our client told us “Galytix is different. It puts data engineering and industry specific knowledge at the heart of its operating system which helps us join up the dots across the whole healthcare value chain.”  
              </div>
            </div>
            <hr />
            <div class="block-section">
              <div class="block-title col-6">
                <span>Improving performance</span>
              </div>
              <div class="block-content col-6">
                Prior to Galytix, our client estimated 90% of its data went unanalyzed. Our customizable, analytical views are connected directly to the ontology, making 100% of ingested data available for insights. Our client liked to drill down into portfolio performance along multiple dimensions e.g., Channel (direct vs external), Product, Region and Client, on a real-time basis. To create actionable insights the insurer set up rules-based automated alerts around high amount claims or flagging odd scenarios where reported loss is unusual for the attributes of the risk or claimant. GX’s wide library of health insurance-specific models supported them in setting relevant thresholds for such metrics.
              </div>
            </div>            
          </div>
        </div>  
      </div>    
    </div>

    


  </div>  
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import BlogPost from "../utilities/gx-slideClient.vue";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { textMaskFrom, textMaskTo} from "../utilities/util";
export default {
  components: {
    VueSlickCarousel,
    BlogPost,
  },
  data() {
    return {
      settings: {
        autoplay:true,
        autoplaySpeed:3000,
        slidesToShow: 1,
        infinite: true,
        responsive: [
          // {
          //   breakpoint: 1441,
          //   settings: {
          //     slidesToShow: 2.3,
          //   },
          // },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      isVisible: false,
    };
  },
  mounted() {},
  methods: {
    visibilityChanged(isVisible) {
      this.isVisible = isVisible;
      if (this.isVisible) {
        this.onEnter();
      }
    },
    onEnter() {
      gsap.registerPlugin(ScrollTrigger);
      const container = document.getElementsByClassName('gx-section--client')[0]
      const title = container.querySelectorAll('h1')[0];
      const slickSlide2 = container.getElementsByClassName("slick-slide");
      const clientSlider = container.getElementsByClassName("client-slider");
      

      gsap.fromTo(title, {
        '-webkit-mask-image': textMaskFrom,
        'mask-image': textMaskFrom,
      }, {
        immediateRender: true,
        "-webkit-mask-image": textMaskTo,
        'mask-image': textMaskTo,
        scrollTrigger: {
          trigger: container,
          start: "top 80%",
          end: "top -10%",
          scrub: true
        }
      });  
      gsap.fromTo(slickSlide2, {
       opacity: 0,
      }, {              
        immediateRender: true,  
        opacity: 1,
        scrollTrigger: {
          trigger: clientSlider,
          start: "top 90%",
          end: "center 50%",
          scrub: true
        }
      }); 
      gsap.fromTo(container, {
       opacity: 1,
      }, {              
        immediateRender: true,  
        opacity: 0,
        scrollTrigger: {
          trigger: clientSlider,
           start: "center 55%",
          end: "bottom 20%",
          scrub: true
        }
      }); 
    },

    onBlogPostLearnMore: function (id) {
      const container = document.getElementsByTagName('body')[0];
      container.style.overflow = 'hidden'; 
      if(id === 'client1')
      {
        this.$refs.client1.style.display  = 'block'; 
      }
      else if(id === 'client2')
      {
        this.$refs.client2.style.display = 'block';
      }
      else if(id === 'client3')
      {
        this.$refs.client3.style.display = 'block';
      }
      else if(id === 'client4')
      {
        this.$refs.client4.style.display = 'block';
      }
      else {
        window.open(id, '_blank');
      }

    },
    onPopupClose: function(id) {
      const container = document.getElementsByTagName('body')[0];
      container.style.overflow = 'visible';
      if(id === 'client1')
      {
        this.$refs.client1.style.display  = 'none'; 
      }
      else if(id === 'client2')
      {
        this.$refs.client2.style.display = 'none';
      }
      else if(id === 'client3')
      {
        this.$refs.client3.style.display = 'none';
      }
      else if(id === 'client4')
      {
        this.$refs.client4.style.display = 'none';
      }

    }

  },
};
</script>


<style lang="scss">

.client-slider .slick-track {  
  display: flex;
  flex-direction: row;
  .slick-slide {
    height: auto;
    display: flex;
    > div {
      width: 100%;
    }
  }
}
.container-client {
  background-color: #161616;
  padding: 80px 20px 100px;
  height: 100vh;
  @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
    height: auto;
    padding: 50px 20px;
  }
}
.gx-section {
  &.gx-section--client {
    height: auto;
    background-repeat: no-repeat;
    background-position: bottom left;
    color: $white;
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow: hidden;
    .container {
      max-width: 1440px;
      margin: 0 auto;
      width: 100%;
      color: $white;
      // @media screen and (max-width: ($mobile-breakpoint)) {
      //   padding: 0 20px;
      // }
      @include breakpoint($min-width: 1239px) {
        max-width: 1440px;
        margin: 0 auto;
        padding: 0;
      }
      h1 {
        margin-bottom: 4rem;
        display: inline-block;
        @include breakpoint($large) {
          margin-bottom: 5rem;
        }
      }
      // .slick-slider {
      //   @media screen and (max-width: 768px) { 
      //     padding: 0 20px 0 0;
      //   }
      // }
    }
  }
}


/* --- popup --- */
.gx-popup {
  background: rgba(0,0,0, 0.8);
  padding: 5% 0;
  position: fixed;
  overflow: auto;
  height: 100vh;
  width: 100%;   
  left: 0;
  // right: 0;
  top: 0;
  bottom: 0;
  // display: block;
  // z-index: 11000;
  .gx-popup-content-wrap {
    margin-left: auto;
    margin-right: auto;
    width: 980px;
    padding: 0 80px;
    
    .popup-content {
      background-color: #161616;   
      .popupClose {
        border: 2px solid #f5f5f7;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;        
        position: fixed;        
        cursor: pointer;
        margin: 20px;
        font-weight: 500;
        color: #f5f5f7;
      }   
      .banner {
        height: 280px;
        background-position: center;
        background-size: cover;
      }
      .banner-client-1{
        background-image: url("../../assets/imgs/client/client-1.jpg");
      }
      .banner-client-2{
        background-image: url("../../assets/imgs/client/client-2.jpg");
      }
      .banner-client-3{
        background-image: url("../../assets/imgs/client/client-3.jpg");
      }
      .banner-client-4{
        background-image: url("../../assets/imgs/client/client-4.jpg");
      }
      .popup-body {
        padding: 50px 8.33333% 80px;
        hr {
          margin:45px 0;
          background-image: linear-gradient(90deg,#006dd8,#8fc7ff);
          border: none;
          height: 2px;
        }
        h1 {
          font-family: $primary-font;          
          font-size: 4.8rem;
          line-height: 1.2;
          font-weight: 600;
          letter-spacing: -.003em;
          margin-bottom: 22px;          
        }
        p {
          font-size: 21px;
          line-height: 1.3;
          font-weight: 600;
          letter-spacing: .011em;
          color: #a6a6a6;
          margin-top: 22px;
        }
        a {
          color: #006DD8;
        }
        .news-companies-logo {  
          display: flex;
          align-items: center; 
          height: 78px;
          margin-top: 20px;
          margin-bottom: 40px;
        }
        .news-companies-logo .newsGalytixLogo,
        .news-companies-logo .newsSocGenLogo {
            width: 100%
        }
        .news-companies-logo .company-logo + .company-logo {
            border-left: 1px solid #8b939b;
        }
        .news-companies-logo .company-logo {
            display: flex;
            height:100%;
            align-items: center;
            padding: 0 10px;
        }
        .block-section {
          display: flex;
          flex-direction: row;
          @media screen and (max-width: ($mobile-breakpoint)) {
            flex-direction: column;;
          }
          .col-6 {
            width: 50%;
            @media screen and (max-width: ($mobile-breakpoint)) {
              width: 100%;
            }
          }
          .block-title span {
            width: 85%;
            display: block;
            font-size: 32px;
            line-height: 1.125;
            font-weight: 600;
            letter-spacing: .004em;
            color: transparent;
            background-image: linear-gradient(to right, #006DD8, #8fc7ff);
            background-clip: text;
            -webkit-background-clip: text;  
            padding-bottom: 10px;
          } 
          .block-content {
            font-size: 1.7rem;
            line-height: 1.23536;
            font-weight: 600;
            color: #a6a6a6;  
            padding-top: 5px;        
          }
        }
        .list-title {
          margin-bottom: 22px;
          span {
            font-size: 32px;
            line-height: 1.125;
            font-weight: 600;
            letter-spacing: .004em;
            color: transparent;
            background-image: linear-gradient(to right, #006DD8, #8fc7ff);
            background-clip: text;
            -webkit-background-clip: text;            
          }
        }
        ul {
          list-style-type: none;
        }
        ul > li {           
          margin-bottom: 1rem;
          font-size: 1.7rem;
          line-height: 1.23536;
          font-weight: 600;
          color: #a6a6a6;
          .key-value .value {
            font-family: Georgia, 'Times New Roman', Times, serif;
            font-size: 4.4rem;
            font-weight: 600;
            color: #f5f5f7;
          } 
        } 
        ul > li + li {
          margin-top: 30px;
        }
      }
    }    
  }
}

.client-slider {
  .slick-prev,
  .slick-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-indent: -999px;
    width: 40px;
    height: 40px;
    background-size: 100% 100%;
    overflow:hidden;
    @media screen and (max-width: ($mobile-breakpoint)) {
      display: none!important;
    }
    @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
      display: none!important;
    }
  }
  .slick-prev {
    right: 101%;  
    background-image: url("../../assets/imgs/prev-arrow.png");
    
  }
  .slick-next {
    left: 101%;
    background-image: url("../../assets/imgs/next-arrow.png");
  }
  .slick-dots {
    display: flex!important;
    flex-direction: row;
    color: #fff;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    @media screen and (max-width: ($mobile-breakpoint)) {
      margin-top: 0px;
    }
  }
  .slick-dots li {
    display: inline-block;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    text-indent: -999px;
    margin: 3px;
    background-color: #f5f5f7;
    cursor: pointer;
  }
  .slick-dots li.slick-active {
    background-color: #006dd8;
  }
}

// @media screen and (max-width: 540px) {
  @media screen and (max-width: ($mobile-breakpoint)) {
  // .media-slider .slick-track {
  //   padding: 0 0 10px;
  //   .slick-slide {
  //     margin-left:0px;
  //   }
  // }
  // .media-slider {
  //   .slick-prev,
  //   .slick-next {
  //     display: none!important; 
  //   }  
     
  // }
  .gx-popup { 
    padding-top: 70px;
    .gx-popup-content-wrap {
      width: 100%;
      padding: 0px;
      .popup-content {
        .popupClose {
          margin: 11px;
        }
        .popup-body {
          padding: 50px 50px 80px;
          h1 {
            font-size: 3.5rem;
            line-height: 4.5rem;
          }
        }
      }
    }
  }

}
@media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
  // .media-slider .slick-track {
  //   padding: 0 0 10px;
  //   .slick-slide {
  //     margin-left:0px;
  //   }
  // }
  // .media-slider {
  //   .slick-prev,
  //   .slick-next {
  //     display: none!important; 
  //   }      
  // }
  .gx-popup { 
    padding-top: 70px;
    .gx-popup-content-wrap {
      width: 100%;
      padding: 0px;
      .popup-content {
        .popupClose {
          margin: 11px;
        }
        .popup-body {
          padding: 50px 50px 80px;
          h1 {
            font-size: 3.5rem;
            line-height: 4.5rem;
          }
        }
      }
    }
  }
}

@media only screen 
  and (min-device-width: 800px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5)
  and (orientation: landscape) { 
  .slide-client .body .heading {
    font-size: 3.8rem;
    line-height: 4.3rem;
  }
  .slide-client .thumbClient {
    max-height:400px;
  }  
  .client-slider {
  .slick-prev,
  .slick-next {
      display: none!important; 
    }
  }  
  .container-client {
    height: auto;
  }
}

@media only screen 
and (min-device-width: 820px) 
and (max-device-width: 1366px) 
and (-webkit-min-device-pixel-ratio: 1.5)
and (orientation: portrait) {  
  .container-client {
    height: auto;
  }
  .client-slider {
  .slick-prev,
  .slick-next {
      display: none!important; 
    }
  } 
}


</style>
 