<template>
  <div class="gx-section gx-section--ipads3">
    <div class="container">
      <div class="left-text_1">
        <h1 id="leftTitle_1"><span class="text-color-3 strong">Predictive </span><br>  Real Time Analytics</h1>
        <div id="leftCopy_1" class="copy">
          <h4 class="text-color-2"> It is constantly fine tuning to find what is relevant in the moment, built for you</h4>
          <div class="gradient-line"></div>
          <h4 class="text-color-2">Algorithm constantly integrates data and fine-tuned to find signals</h4>
          <!-- <h5 class="text-color-2">Constantly fine-tuned to find signals relevant in the current moment.</h5> -->
        </div>
      </div>
    </div>
    <div class="ipad">
      <img src="../../assets/imgs/gx-tablet.svg" alt="" />
      <video id="videoThree" autoplay playsinline loop muted="muted" ref="video">
        <source src="../../assets/videos/section4.mp4" />
      </video>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { textMaskFrom, textMaskTo, } from "../utilities/util";

export default {
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    animations() {
      gsap.registerPlugin(ScrollTrigger);

      const container = document.querySelector(".gx-section--ipads3");
      const title = container.querySelector("#leftTitle_1");
      const copy = container.querySelector("#leftCopy_1");

      const ipad = container.querySelector(".ipad");
      const video = ipad.querySelector("#videoThree");

      video.play();

       gsap.fromTo(".left-text_1", {
        opacity:0,
        '-webkit-mask-image': textMaskFrom,
        'mask-image': textMaskFrom,
      }, {
        opacity:1,
        immediateRender: true,
        "-webkit-mask-image": textMaskTo,
        'mask-image': textMaskTo,
        scrollTrigger: {
          trigger: title,
          start: "top 100%",
          end: "bottom 10%",
          scrub: true
        }
      })

      gsap.fromTo(ipad, {
        transform: "translateX(100vw)",
        opacity: 0,
      }, {
        transform: window.innerWidth > 768 ? "translateX(30vw)" : "translateX(0)",
        opacity: 1,
        duration: 10,
        immediateRender: false,
        scrollTrigger: {
          trigger: container,
          start: "top 80%",
          end: "top 40%",
          scrub: true
        },
      })
      gsap.fromTo(".gx-section--ipads3", {
        opacity: 1,
        duration: 1,
        immediateRender: false,
      }, {
        opacity: 0,
        duration: 10,
        immediateRender: false,
        scrollTrigger: {
          trigger: container,
          start: "center 30%",
          end: "center -20%",
          scrub: true,
        },
      })

      gsap.fromTo(video, {
        opacity: 0,
      }, {
        opacity: 1,
        duration: 0.5,
        immediateRender: false,
        scrollTrigger: {
          trigger: container,
          start: "top 80%",
          end: "top 40%",
          scrub: true,
        },
      })
      gsap.fromTo(video, {
        opacity: 1,
        duration: 1,
        immediateRender: false,
      }, {
        opacity: 0,
        duration: 10,
        immediateRender: false,
        scrollTrigger: {
          trigger: container,
          start: "center 10%",
          end: "center -30%",
          scrub: true
        },
      })
      
      gsap.fromTo(copy, {
        opacity: 0,
        duration: 1,
        transform: "translateX(0)",
        immediateRender: false,
      }, {
        opacity: 1,
        duration: 1,
        // transform: window.innerWidth > 768 ? "translateY(0)" : "translateY(-20vh)",
        immediateRender: false,
        scrollTrigger: {
          trigger: container,
          start: "top 80%",
          end: "top 40%",
          scrub: true
        },
      })
      gsap.fromTo(copy, {
        opacity: 1,
        duration: 1,
        immediateRender: false,
      }, {
        opacity: 0,
        duration: 1,
        immediateRender: false,
        scrollTrigger: {
          trigger: container,
          start: "center 10%",
          end: "center -30%",
          scrub: true,
        },
      })
    },
  },
  mounted() {
    this.animations();
  },
};
</script>


<style lang="scss">
.gx-section--ipads3 {
  min-height: 100vh;
  height: 100vh;
  background: $black;
  background-repeat: no-repeat;
  background-position: bottom left;
  color: $white;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: visible;
  position: relative;
  // @media screen and (max-width: ($mobile-breakpoint)) {
  //     height: 120vh;          
  //   }

  .container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    display: flex;

    @media screen and (max-width: ($mobile-breakpoint)) {
      height: 100vh;          
    }

    .left-text_1 {
      height: 100vh;
      width: 50%;
      color: white;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-right: 25px;
      @media screen and (max-width: ($mobile-breakpoint)) {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        padding: 0px 0;
        justify-content: space-between;
        width: 100%;
        height: 100%;          
      }

      a,
      button {
        opacity: 0;
      }

      h1 {
        margin-bottom: 4rem;

        @include breakpoint($large) {
          margin-bottom: 5rem;
        }
        @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
          margin-bottom: 2rem;
        }
      }

      h4 {
        margin-bottom: 2.5rem;
        font-weight: bold;
        color: transparent;
        background-image: linear-gradient(to right, #006DD8, #66b3ff);
        -webkit-background-clip:text;
        +h4 {
          margin-bottom: 0;
        }
        @media screen and (max-width: ($mobile-breakpoint)) {
          font-weight: 500;
        }
        @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
          margin-bottom: 2vw;
        }
      }

      .btn {
        margin-top: 3rem;

        @include breakpoint($large) {
          margin-top: 4rem;
        }
      }
    }
  }

  .ipad {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: translate(100vw);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    @media screen and (max-width: ($mobile-breakpoint)) {
      top: -270px;
    }

    img,
    video {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    img {
      box-shadow: 0px 0px 79px 29px rgba(0, 109, 216, 0.5);
      border-radius: 37px;
      width: 55%;

      @media screen and (max-width: ($mobile-breakpoint)) {
        width: 80%;
        border-radius: 10px;
      }
    }

    video {
      width: 50%;
      opacity: 0;

      @media screen and (max-width: ($mobile-breakpoint)) {
        width: 75%;
      }
    }
  }
}
@media only screen 
  and (min-device-width: 800px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5)
  and (orientation: landscape) { 
  .gx-section--ipads3 .container .left-text h1 {
    margin-bottom: 3rem;
  }
}
</style>
 