<template>
  <div class="gx-section gx-section--six VideoBg" v-observe-visibility="visibilityChanged">
    <div id="gx-section-six-text">
      <h1 class="top-heading text-color-3">AI4DE</h1>
      <div id="gx-section-six-text-1">
        <h1>Ontology Connecting Data Into A Single Data Ecosystem</h1>
        <h4 class="text-color-4">Making your data more aware and turning it into truth.</h4>
      </div>
      <div id="gx-section-six-text-2">        
        <h1>
          <!-- Pipeline-Driven Architecture Enabled By Human Supervised Algorithms -->
          Pipeline Architecture Enabled By Human Supervised Algos
        </h1>
        <h4 class="titleHeading text-color-4"> Data Quality addressed using AI based tools doing Detection, Correction, Repair</h4>
      </div>
    </div>
    
      <video autoplay class="brainDesktop gx-section-six-video-desktop" muted="muted" playsinline="true" id="brainDesktop"
        src="@/assets/videos/section6.mp4" @play="changeTextAnimation()" @ended="replayDesktop()"></video>
    <div class="brainMobile-wrap">
      <video autoplay class="brainMobile gx-section-six-video-mobile" muted="muted" playsinline="true" id="brainMobile"
        src="@/assets/videos/section6-mobile.mp4" @play="changeTextAnimation()" @ended="replayMobile()"></video>
    </div>
  </div>
</template>

<script>
import gsap, { Power4 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { textMaskFrom, textMaskTo } from "../utilities/util";

export default {
  data() {
    return {
      mobile: false,
      alreadyAnimatingText: false
    };
  },

  mounted() {
    this.videoAnimation();
    this.mobile = window.innerWidth <= 760;
    this.changeTextAnimation();
  },
  methods: {
    visibilityChanged(isVisible) {
      if (isVisible) {
        this.changeTextAnimation();
      }
    },
    replayDesktop() {
      const brainVideoDesktop = document.getElementById("brainDesktop");
      brainVideoDesktop.play();
    },
    replayMobile() {
      const brainVideoMobile = document.getElementById("brainMobile");
      brainVideoMobile.play();
    },
    changeTextAnimation() {
      if (this.alreadyAnimatingText) {
        return;
      }
      this.alreadyAnimatingText = true;

      const delay = 5;
      const duration = 4;

      gsap.timeline({
      }).fromTo("#gx-section-six-text-1, #gx-section-six-text-1-bottom", {
        opacity: 1,
        immediateRender: false,
      }, {
        immediateRender: false,
        opacity: 0,        
        delay,
        repeat: -1,
        yoyo: true,
        ease: Power4.easeInOut,
        duration,
      });

      gsap.timeline({
      }).fromTo("#gx-section-six-text-2, #gx-section-six-text-2-bottom", {
        opacity: 0,
        immediateRender: false,
      }, {
        immediateRender: false,
        opacity: 1,        
        delay,
        repeat: -1,
        yoyo: true,
        duration,
        ease: Power4.easeInOut,
      });

    },
    videoAnimation() {
      gsap.registerPlugin(ScrollTrigger);

      const gxSectionSix = document.querySelector(".gx-section--six");
      const brainVideoDesktop = document.getElementById("brainDesktop");
      const brainVideoMobile = document.getElementById("brainMobile");

      brainVideoMobile.play();
      brainVideoDesktop.play();

      gsap.fromTo("#gx-section-six-text", {
        '-webkit-mask-image': textMaskFrom,
        'mask-image': textMaskFrom,
      }, {
        immediateRender: true,
        "-webkit-mask-image": textMaskTo,
        'mask-image': textMaskTo,
        scrollTrigger: {
          trigger: gxSectionSix,
          start: window.innerWidth > 768 ? "top 60%" : "top 70%",
          // start: "top 60%",
          end: "top -30%",
          // end: "top -30%",
          scrub: true,
        }
      });

      gsap.fromTo(".gx-section-six-video-desktop", {
        opacity: 0,
        y: 0,
      }, {
        duration: 3,
        y: "-33vh",
        opacity: 1,
        immediateRender: true,
        scrollTrigger: {
          trigger: gxSectionSix,
          start: "top 20%",
          end: "bottom 60%",
          scrub: true,
        }
      });     

      gsap.fromTo(".gx-section--six", {
        opacity: 1,
      }, {
        immediateRender: true,
        opacity: 0,
        scrollTrigger: {
          trigger: gxSectionSix,
          start: window.innerWidth > 768 ? "center 50%" : "center 20%",
          // start: "center 50%",
          end:window.innerWidth > 768 ? "center -50%" : "bottom 30%",
          // end: "center -50%",
          scrub: true
        }
      });

    },
  },
};
</script>


<style lang="scss">
.top-heading {
  position: absolute;
  top: 15vh;
  left: 50%;
  transform: translateX(-50%);
}
.gx-section--six {
  background: $black;
  background-repeat: no-repeat;
  background-position: bottom left;
  color: $white;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  @media screen and (max-width: ($mobile-breakpoint)) {
    justify-content:unset ;    
  }  
  &-two {
    display: none;
    @media screen and (max-height: 1100px) and (min-width: 768px) {
      display: flex;
    }    
  }  
  h4 {
      font-weight: bold;
    
      @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
        height: 100px;
      }
    }
  #gx-section-six-text {
    margin: 0 auto;
    text-align: center;
    padding-top: 25vh;
    z-index: 9;
    position: relative;

    h1 {
      width: 100%;
      margin: 0 auto;
      margin-bottom: 4rem;
      
      @include breakpoint($medium) {
        width: 85%;
        margin-bottom: 5rem;
      }
      @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
        max-width: 85%;
        margin-bottom: 2rem;
      }
    }    
  }

  #gx-section-six-text-1 {
    opacity: 0;
  }
  #gx-section-six-text-2 {
    position: absolute;
    top: 25vh;
    opacity: 0;
  }


  .gx-section-six-text-bottom {
    position: absolute;
    bottom: 33vh;
    left: 50%;
    transform: translateX(-50%);
  }
  #gx-section-six-text-1-bottom {
    opacity: 0;
    text-align: center;
  }
  #gx-section-six-text-2-bottom {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
  }

  .brainDesktop,
  .brainMobile {
    min-width: 100%;
    min-height: 100%;
    margin: auto;
    object-fit: cover;
    // @media screen and (max-width: ($mobile-breakpoint)) {
    //   object-fit:
    // }
    @include breakpoint($medium) {
      min-width: auto;
      min-height: auto;
      width: 100%;
    }
  }

  .brainDesktop {
    display: none;
    @include breakpoint($large) {
      display: block;
    }
    @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
      display: block;
    }
  }
  .brainMobile-wrap {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 420px;
    position: relative;
    margin-bottom:50vh;

    @include breakpoint($large) {
      display: none;
    }
    @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
      display: none;
    }
    .brainMobile {
      display: block;      
      position: absolute;
      top: 50%;
      transform: translateY(-50%);      
    }
  }
}

@media screen and (max-width: ($mobile-breakpoint)) {
  .sections .gx-section--six {
    padding-left:0;
    padding-right:0;
    // height: 180vh;
    #gx-section-six-text-1,
    #gx-section-six-text-2 {
      min-height: 37vh;
      padding-left:20px;
      padding-right:20px;
    }
  }
  #gx-section-six-text {
    &> h1 {
      text-align: left;
      padding-left: 20px;
      padding-right: 20px;
    }
    h1 {
      text-align: left;
    }
    h4 {
      text-align: left;
      font-weight: 500;
    }
  }
}

@media only screen 
and (min-width: 1024px) 
and (orientation: portrait) {  
  .gx-section.gx-section--six {
    min-height: 130vh;
  }
  .sections .gx-section--six {    
    #gx-section-six-text-1,
    #gx-section-six-text-2 {
      min-height: 30vh;      
    }
  }
}

@media only screen 
and (min-device-width: 800px) 
and (max-device-width: 1366px) 
and (-webkit-min-device-pixel-ratio: 1.5)
and (orientation: landscape) {  
  .gx-section--six #gx-section-six-text h1 {
    width: 100%;
  }
}
</style>
 