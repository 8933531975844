<template>
  <section class="slide-container-client">
    <div class="slide-client">
      <div class="thumbClient" :style="`background-image: url(${publicPath}/static/assets/imgs/client/${source})`"></div>
      <!-- <div class="header">
        <h6 class="smallHeading">{{ smallHeading }}</h6>        
      </div> -->
      <div class="body">
        <h6 class="heading">{{ heading }}</h6>
        <div class="client-content">{{ content }}</div>
        <a v-on:click="learnMore" class="link btn-sm-primary">Read More</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  emits: ['learnMore'],
  props: {
    // smallHeading: {
    //   type: String,
    //   required: true,
    // },
    heading: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    source: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      publicPath: process.env.NODE_ENV === "production" ? "/gx/" : "",
    };
  },
  methods: {
    learnMore: function() {
      this.$emit('learnMore', this.link);
    }
  } 
};
</script>

<style lang="scss">

.slide-container-client {
  position: relative;
  // padding: 15px;
}
.slide-client {
  display: flex;
  flex-direction: row;
  min-height: 450px;
  @media screen and (max-width: ($mobile-breakpoint)) {
    flex-direction: column;
  }
  @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
    min-height: auto;
  }
  &:hover {
      .thumbClient {      
        background-size: auto 120%;
        transition: all 0.5s ease;
      }
    }

  .thumbClient {
    max-height: 450px;
    // background-size: cover;
    width: 50%;
    // background-position: center;

    background-size:auto 100%;
    background-repeat: no-repeat;
    background-position: center center;
  // background-color: transparent;
    transition: all 0.5s ease;  
    @media screen and (max-width: ($mobile-breakpoint)) {
      width: 100%;
      height: 230px;
    }  
    @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
      max-height: 230px;
      width: 30%;
    }
  }
  .header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-items: center;
    z-index: 2;
    position: relative;
    .smallHeading {
      font-family: $secondary-font;
      font-weight: 500;
      color: $white;
      padding: 15px 0px;
      font-size: 1.4rem;
      // margin-bottom:1rem ;
    }    
  }
  .body {
    position: relative;
    z-index: 2;
    width: 50%;
    padding: 0rem 0 2rem 6rem;
    @media screen and (max-width: ($mobile-breakpoint)) {
      width: 100%;
      padding-left: 0rem;
    }
    @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
      padding: 0rem 0 2rem 3rem;
      width: 70%;
    }
    .heading {
      color: $white;
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 4.8rem;
      line-height: 5.3rem;
      margin-bottom: 3rem;
      @media screen and (max-width: ($mobile-breakpoint)) {
        font-size: 2.5vh;
        line-height: 3.3vh;
        margin-top: 3rem;
      }
      @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
        font-size: 2.5vw;
        line-height: 3.5vw;
        margin-top: 3rem;
        margin-top: 0rem;
      }
    }
    .client-content {
      color: #a6a6a6;
      font-family: $primary-font;
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 3.1rem;
      margin-bottom: 3rem;
      @media screen and (max-width: ($mobile-breakpoint)) {
        display: none;
      }
      @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
        font-size: 2.0vw;
        line-height: 2.5vw;
      }
    }
    
  }
}
</style>
