<template>
  <section class="slide-container">
    <div
      class="slide"
      :style="`background-image: url(${publicPath}/static/assets/imgs/${source})`"
    >
      <div class="header">
        <h6 class="tag">{{ tagName }}</h6>
        <h6 class="date">{{ date }}</h6>
      </div>
      <div class="body">
        <h5>{{ content }}</h5>
        <a :href="link" class="link btn-sm-primary">Read More</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    tagName: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    source: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      publicPath: process.env.NODE_ENV === "production" ? "/gx/" : "",
    };
  },
};
</script>

<style lang="scss">
.slide-container {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  overflow: hidden;
  padding: 0 10px;
  min-height: 560px;
  background-size: cover;
  @media screen and (max-width: ($mobile-breakpoint)) {
    padding: 0;
  }
  &:hover {
    .slide {
      background-size: 120% 120%;
      transition: all 0.5s ease;
    }
  }
}
.slide {
  padding: 20px;  
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 560px;
  z-index: 2;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  transition: all 0.5s ease;
  border-radius: 5px;
  &:after {
    content: "";
    position: absolute;
    display: block;
    background: rgba(0,0,0,0.3);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
  .header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-items: center;
    margin-bottom: 20rem;
    z-index: 2;
    position: relative;
    .tag {
      background: $blue;
      color: $white;
      padding: 7px 10px;
      font-size: 1.4rem;
      line-height: 2.2rem;
    }
    .date {
      color: $white;
      line-height: 3.6rem;
      font-size: 1.4rem;
    }
  }
  .body {
    width: 75%;
    position: relative;
    z-index: 2;
    .link {
      margin-top: 3.5rem;
      background-color: #000000;
      // font-size: 1.4rem;
      // line-height: 2.2rem;
    }
  }
}

.perspective-slider .slide-container {
  @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
    min-height: auto;
  }
}
.perspective-slider .slide {
  background-size: auto 100%;
  // @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
  //   background-size: 100% auto ;
  //   min-height: 325px;
  // }
}
.perspective-slider .slide-container:hover .slide {
  background-size: auto 120%;
  // @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
  //   background-size: 120% auto ;
  // }
  .perspective-slider .slide .header {
    @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
    margin-bottom: 7rem;
    }
  }
}
</style>
