<template>
  <div class="gx-section gx-section--eight" v-observe-visibility="visibilityChanged">
    <div class="container">
      <h1><span class="text-color-3">Perspectives</span> <br>Our Thinking </h1>
      <VueSlickCarousel :arrows="true" :dots="true" v-bind="settings" class="perspective-slider">
        <BlogPost
          :tagName="`EWS`"
          :date="`June 2022`"
          :content="`Banks must act on their early warning systems or risk ROE downturn`"
          :link="`/perspectives/banks-must-act-on-their-early-warning-systems-or-risk-roe-downturn`"
          :source="`post-1.jpg`"
        />
        <BlogPost
          :tagName="`EWS`"
          :date="`April 2022`"
          :content="`Corporate lenders must be on lookout for early signs of default - FT`"
          :link="`/perspectives/corporate-lenders-must-be-on-lookout-for-early-signs-of-default`"
          :source="`post-2.jpg`"
        />
        <BlogPost
          :tagName="`EWS`"
          :date="`February 2022`"
          :content="`Bank risk managers aren't ready for wave of corporate credit defaults - American Banker`"
          :link="`/perspectives/bank-risk-managers-arent-ready-for-wave-of-corporate-credit-defaults`"
          :source="`post-3.jpg`"
        />
        <BlogPost
          :tagName="`EWS`"
          :date="`January 2022`"
          :content="`Data Engineering - Enterprise-Scale, End-to-End Streams`"
          :link="`/perspectives/data-engineering-enterprise-scale-end-to-end-streams`"
          :source="`post-4.jpg`"
        />
        <BlogPost
          :tagName="`EWS`"
          :date="`January 2022`"
          :content="`Making machine intelligence profitable`"
          :link="`/perspectives/making-machine-intelligence-profitable`"
          :source="`post-5.jpg`"
        />
        <BlogPost
          :tagName="`EWS`"
          :date="`January 2022`"
          :content="`How is external data transforming the commercial insurance and reinsurance sectors?`"
          :link="`/perspectives/how-is-external-data-transforming-the-commercial-insurance-and-reinsurance-sectors`"
          :source="`post-6.jpg`"
        />
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import BlogPost from "../utilities/gx-slide.vue";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { textMaskFrom, textMaskTo } from "../utilities/util";
export default {
  components: {
    VueSlickCarousel,
    BlogPost,
  },
  data() {
    return {
      settings: {
        autoplay:true,
        autoplaySpeed:3000,
        slidesToShow: 3,
        infinite: true,
        responsive: [
          // {
          //   breakpoint: 1441,
          //   settings: {
          //     slidesToShow: 2.3,
          //   },
          // },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      isVisible: false,
    };
  },
  mounted() {},
  methods: {
    visibilityChanged(isVisible) {
      this.isVisible = isVisible;
      if (this.isVisible) {
        this.onEnter();
      }
    },
    onEnter() {
      gsap.registerPlugin(ScrollTrigger);
      const container = document.getElementsByClassName('gx-section--eight')[0]
      const title = container.querySelectorAll('h1')[0];
      const slickSlide = container.getElementsByClassName("slick-slide");
      

      gsap.fromTo(title, {
        '-webkit-mask-image': textMaskFrom,
        'mask-image': textMaskFrom,
      }, {
        immediateRender: true,
        "-webkit-mask-image": textMaskTo,
        'mask-image': textMaskTo,
        scrollTrigger: {
          trigger: container,
          start: "top 80%",
          end: "top -10%",
          scrub: true
        }
      });  
      gsap.fromTo(slickSlide, {
        opacity:0,      
      }, {
        opacity:1,
        stagger:window.innerWidth > 768 ? "0.1" : "0.1",
        // stagger:0.1,
        duration:window.innerWidth > 768 ? "0.2" : "2",
        // duration:0.2,       
        immediateRender: true,        
        scrollTrigger: {
          trigger: '.perspective-slider',
          // trigger: container,
          start: "top 90%",
          end: "center 70%",
          scrub: true
        }
      }); 
    }
  },
};
</script>


<style lang="scss">
.gx-section {
  &.gx-section--eight {
    height: auto;
    background-repeat: no-repeat;
    background-position: bottom left;
    color: $white;
    display: flex;
    flex-direction: column;
    // padding: 50px 0 0;
    padding: 50px 20px;
    // padding-left: 20px;
    overflow: hidden;
    .container {
      max-width: 1440px;
      margin: 0 auto;
      width: 100%;
      color: $white;
      @include breakpoint($min-width: 1239px) {
        max-width: 1440px;
        margin: 0 auto;
        padding: 0;
      }
      h1 {
        margin-bottom: 4rem;
        display: inline-block;
        @include breakpoint($large) {
          margin-bottom: 5rem;
        }
      }
      .slick-slider {
        margin-bottom: 10rem;
        @media screen and (max-width: ($mobile-breakpoint)) {
          margin-bottom: 5rem;
        }
        @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
          margin-bottom: 1rem;
        }
      }
    }
  }
}


.perspective-slider {
  .slick-prev,
  .slick-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-indent: -999px;
    width: 40px;
    height: 40px;
    background-size: 100% 100%;
    overflow:hidden;
    @media screen and (max-width: ($mobile-breakpoint)) {
      display: none!important;
    }
    @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
      display: none!important;
    }
  }
  .slick-prev {
    right: 101%;  
    background-image: url("../../assets/imgs/prev-arrow.png");
    
  }
  .slick-next {
    left: 101%;
    background-image: url("../../assets/imgs/next-arrow.png");
  }
  .slick-dots {
    display: flex!important;
    flex-direction: row;
    color: #fff;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }
  .slick-dots li {
    display: inline-block;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    text-indent: -999px;
    margin: 3px;
    background-color: #f5f5f7;
    cursor: pointer;
  }
  .slick-dots li.slick-active {
    background-color: #006dd8;
  }
  
}


@media only screen 
  and (min-device-width: 800px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5)
  and (orientation: landscape) { 
    .perspective-slider {
      .slick-prev,
      .slick-next {
        display: none!important;
      }
    }
  }
  @media only screen 
and (min-device-width: 820px) 
and (max-device-width: 1366px) 
and (-webkit-min-device-pixel-ratio: 1.5)
and (orientation: portrait) {
  .perspective-slider {
      .slick-prev,
      .slick-next {
        display: none!important;
      }
    }
  .gx-section.gx-section--eight {
    min-height: auto;
  }
}
</style>
 