<template>
  <div class="gx-section gx-section--ipads2">
    <div class="container">
      <div class="right-text">
        <h1 id="rightTitle"><span class="text-color-3 strong">Automated</span><br> Credit Modelling</h1>
        <div class="copy" id="rightCopy">
          <h4 class="text-color-4">Synthetic financial statements from transactional data</h4>
          <div class="gradient-line"></div>
          <!-- <h4 class="text-color-2">Tailored digitalization model to forecast client's financials.</h4> -->
          <h4 class="text-color-4">Algorithmic integration of data</h4>
          <div class="gradient-line"></div>
          <!-- <h4 class="text-color-2">Peer benchmarking.</h4> -->          
          <h4 class="text-color-4">Making excel redundant</h4>
        </div>
      </div>
    </div>
    <div class="ipad">
      <img src="../../assets/imgs/gx-tablet.svg" alt="" />
      <video id="videoTwo" autoplay playsinline loop muted="muted" ref="video">
        <source src="../../assets/videos/section3.mp4" />
      </video>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { textMaskFrom, textMaskTo, } from "../utilities/util";

export default {
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    animations() {
      gsap.registerPlugin(ScrollTrigger);

      const container = document.querySelector(".gx-section--ipads2");
      const title = container.querySelector("#rightTitle");
      const copy = container.querySelector("#rightCopy");
      const rightText = container.querySelector(".right-text");
      const ipad = container.querySelector(".ipad");
      const video = ipad.querySelector("#videoTwo");

      video.play();

      gsap.fromTo( rightText, {
        opacity:0,
        '-webkit-mask-image': textMaskFrom,
        'mask-image': textMaskFrom,

      }, {
        opacity:1,
        immediateRender: true,
        "-webkit-mask-image": textMaskTo,
        'mask-image': textMaskTo,
        scrollTrigger: {
          trigger: title,
          start: "top 100%",
          end: "bottom 10%",
          scrub: true
        }
      })

      gsap.fromTo(ipad, {
        transform: "translateX(-100vw)",
        opacity: 0,
      },{
        transform: window.innerWidth > 768 ? "translateX(-30vw)" : "translateX(0)",
        opacity: 1,
        duration: 10,
        immediateRender: false,
        scrollTrigger: {
          trigger: container,
          start: window.innerWidth > 768 ? "top 80%" : "top 60%",
          // start: "top 80%",
          end: window.innerWidth > 768 ? "top 40%" : "top 10%",
          // end: "top 40%",
          scrub: true
        },
      })
      gsap.fromTo(ipad, {
        opacity: 1,
        duration: 1,
        immediateRender: false,
      }, {
        opacity: 0,
        duration: 10,
        immediateRender: false,
        scrollTrigger: {
          trigger: container,
          start: "center 10%",
          end: "center -30%",
          scrub: true,
        },
      })

      gsap.fromTo(video, {
        opacity: 0,
      },{
        opacity: 1,
        duration: 0.5,
        immediateRender: false,
        scrollTrigger: {
          trigger: container,
          start: "top 80%",
          end: "top 70%",
          scrub: true,
        },
      })
      gsap.fromTo(".gx-section--ipads2", {
        opacity: 1,
        duration: 1,
        immediateRender: false,
      }, {
        opacity: 0,
        duration: 10,
        immediateRender: false,
        scrollTrigger: {
          trigger: container,
          start: "center 30%",
          end: "center -20%",
          scrub: true
        },
      })
      gsap.fromTo(copy, {
        opacity: 0,
        duration: 1,
        transform: "translateY(0)",
        immediateRender: false,
      }, {
        opacity: 1,
        duration: 1,
        // transform: window.innerWidth > 768 ? "translateY(0)" : "translateY(-20vh)",
        immediateRender: false,
        scrollTrigger: {
          trigger: container,
          start: "top 80%",
          end: "top 40%",
          scrub: true
        },
      })      
    },
  },
  mounted() {
    this.animations();
  },

};
</script>


<style lang="scss">
.gx-section--ipads2 {
  min-height: 100vh;
  height: 100vh;
  background: $black;
  background-repeat: no-repeat;
  background-position: bottom left;
  color: $white;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: visible;
  position: relative;
  @media screen and (max-width: ($mobile-breakpoint)) {
    height: 140vh;
  }
  .container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    display: flex;

    @media screen and (max-width: ($mobile-breakpoint)) {
      height: 100vh;
    }

    .right-text {
      height: 100vh;
      width: 50%;
      margin-left: 50%;
      color: $white;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-left:25px;
      @media screen and (max-width: ($mobile-breakpoint)) {     
        margin-left: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        padding: 0px 0;
        justify-content: space-between;
        width: 100%;
        height: 100%;        
      }

      a,
      button {
        opacity: 0;
      }

      h1 {
        margin-bottom: 4rem;
        @include breakpoint($large) {
          margin-bottom: 5rem;
        }
        @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
          margin-bottom: 2rem;
        }
      }

      h4 {
        margin-bottom: 2.5rem;
        font-weight: bold;
        @media screen and (max-width: ($mobile-breakpoint)) {
          font-weight: 500;
        }
        @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
          margin-bottom: 2vw;
        }
      }
      
      .btn {
        margin-top: 3rem;

        @include breakpoint($large) {
          margin-top: 4rem;
        }
      }
    }
  }

  .ipad {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: translate(-100vw);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    @media screen and (max-width: ($mobile-breakpoint)) {
      top: -305px;
    }

    img,
    video {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    img {
      box-shadow: 0px 0px 79px 29px rgba(0, 109, 216, 0.5);
      border-radius: 37px;
      width: 55%;

      @media screen and (max-width: ($mobile-breakpoint)) {
        width: 80%;
        border-radius: 10px;
      }
    }

    video {
      width: 50%;
      opacity: 0;

      @media screen and (max-width: ($mobile-breakpoint)) {
        width: 75%;
      }
    }
  }
}

@media only screen 
  and (min-device-width: 800px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5)
  and (orientation: landscape) { 
  .gx-section--ipads2 .container .left-text h1 {
    margin-bottom: 3rem;
  }
}

</style>
 