<template>
  <div class="gx-section gx-section--seven">
    <div class="bg-mobile"></div>
    <div class="title-heading" v-observe-visibility="visibilityChanged">
      <h1 class="heading"><span class="text-color-3">Disruptive Data Platform</span><br> Financial Institutions</h1>
    </div>
    <TransitionGroup class="grid-container" tag="div">
      <gxCell
        transition="fade"
        v-for="(cell, index) in cells"
        :key="index"
        :symbolBefore="cell.symbolBefore"
        :title="cell.title"
        :symbolAfter="cell.symbolAfter"
        :description="cell.description"
        :postTitle="cell.postTitle"
        :subTitle="cell.subTitle"
      />
    </TransitionGroup>
    <h4 class="text-color-4 textInternal2">Generations ahead of any <br class="show-xlg show-lg" />other data platform</h4>    
  </div>
</template>

<script>
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { textMaskFrom, textMaskTo } from "../utilities/util";
import gxCell from "../utilities/gx-cell.vue";
export default {
  components: {
    gxCell,
  },
  data() {
    return {
      cells: [
        {
          title: "10",
          symbolBefore: null,
          symbolAfter: "x",
          description:
            "Predictive power in generating risk insights and signals",
          subTitle: null,
          postTitle: null,
        },
        {
          title: "100",
          symbolBefore: null,
          symbolAfter: "%",
          description:
            "Data accuracy guaranteed across structured and unstructured sources",
          subTitle: "Up to",
          postTitle: null,
        },
        {
          title: "90",
          symbolBefore: null,
          symbolAfter: "%",
          description: "Automation in engineering of unstructured data",
          subTitle: null,
          postTitle: null,
        },
        {
          title: "100",
          symbolBefore: null,
          symbolAfter: "%",
          description:
            "GX DataFactory with algorithms engineering data supervised and enhanced by expert human engineers",
          subTitle: null,
          postTitle: "algorithmic",
        },
        {
          title: "100",
          symbolBefore: null,
          symbolAfter: "%",
          description:
            "Enterprise data model ontology for internal & external data. All Data in One Model. One Model for All Data.",
          subTitle: null,
          postTitle: "connected",
        },
        {
          title: "95",
          symbolBefore: null,
          symbolAfter: "%",
          description:
            "Accuracy of financial spreading analysis from classifiers",
          subTitle: null,
          postTitle: null,
        },
        {
          title: "50",
          symbolBefore: "~ ",
          symbolAfter: "%",
          description: "Reduction in data transformation\u00a0 timeline",
          subTitle: null,
          postTitle: null,
        },
        {
          title: "60",
          symbolBefore: null,
          symbolAfter: "%",
          description:
            "Data available at sector and sub-segment level for analysis",
          subTitle: null,
          postTitle: "more",
        },
      ],
      isVisible: false,
    };
  },
  mounted() {},
  methods: {
    visibilityChanged(isVisible) {
      this.isVisible = isVisible;
      const cells = document.getElementsByClassName("cell");
      if (this.isVisible) {
        this.onEnter(cells);
      }
    },
    onEnter(items) {
      gsap.registerPlugin(ScrollTrigger);
      const container = document.getElementsByClassName('gx-section--seven')[0]
      // const gridContainer = container.getElementsByClassName('.grid-container')[0];
    
      gsap.fromTo(".heading", {
        '-webkit-mask-image': textMaskFrom,
        'mask-image': textMaskFrom,
      }, {
        immediateRender: true,
        "-webkit-mask-image": textMaskTo,
        'mask-image': textMaskTo,
        scrollTrigger: {
          trigger: container,
          start:window.innerWidth > 768 ? "top 80%" : "top 90%",
          // start: "top 80%",
          end: window.innerWidth > 768 ? "top 10%" : "top 10%",
          // end: "top 10%",
          scrub: true
        }
      });         
      

      gsap.fromTo('.textInternal2', {
        '-webkit-mask-image': textMaskFrom,
        'mask-image': textMaskFrom,
      }, {
        immediateRender: true,
        "-webkit-mask-image": textMaskTo,
        'mask-image': textMaskTo,
        scrollTrigger: {
          trigger: container,
          start: "center 90%",
          end: "bottom 10%",
          scrub: true,
          // markers:true
        }
      });
      
      gsap.fromTo(".title, .cell-content", {
        opacity:1,      
      }, {
        opacity:window.innerWidth > 992 ? "0" : "1",
        stagger:0.1,
        duration:0.4,       
        immediateRender: true,        
        scrollTrigger: {
          trigger: container,
          start:window.innerWidth > 992 ? "center 80%" : " ",
          // start: "center 80%",
          end:window.innerWidth > 992 ? "center -30%" : " ",
          // end: "center -30%",
          scrub: true
        }
      });

      gsap.fromTo(".textInternal2", {
        opacity:1,      
      }, {
        opacity:window.innerWidth > 992 ? "0" : "1",           
        immediateRender: true,        
        scrollTrigger: {
          trigger: container,
          // start:window.innerWidth > 768 ? "center 10%" : "",
          start: "center 10%",
          // end:window.innerWidth > 768 ? "center 50%" : "bottom -10%",
          end: "center -50%",
          scrub: true
        }
      });

      gsap.fromTo(container, {
        opacity:1,      
      }, {
        opacity:window.innerWidth > 992 ? " " : "0",           
        immediateRender: true,        
        scrollTrigger: {
          trigger: '.textInternal2',
          start:window.innerWidth > 992 ? " " : "top 50%",          
          end:window.innerWidth > 992 ? " " : "bottom 30%",          
          scrub: true
        }
      });
      items.forEach((item, index) => {
        let target = { val: 0 };
        let number = item.querySelector("h1 > .number");       
        gsap.to(item, {
          duration: 0.2,
          opacity: 1,
          scale: 1,
          delay: index * 0.15,
        });        
        gsap.to(target, {
          val: this.cells[index].title,
          duration: 2,
          onUpdate: function () {
            number.innerHTML = target.val.toFixed(0);
          },
          delay: index * 0.15,
        });
      });
    },
  },
};
</script>


<style lang="scss">
.gx-section--seven {
  background-repeat: no-repeat;
  background-position: bottom left;
  color: $white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  @include breakpoint($medium) {    
  background: radial-gradient(
    circle at 50% 38%, 
    #006dd8 0.87%, 
    rgba(0, 0, 0, 0) 40%);
  }
  @include iPad() {
    background: none;
  }
  .bg-mobile {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: radial-gradient(
      circle at 50% 50%,
      #006dd8 0.87%,
      rgba(0, 0, 0, 0) 60%
    );
    @include breakpoint($medium) {
      display: none;
    }
    @include iPad() {
      display: block !important;
    }
    @media screen and (max-width: ($mobile-breakpoint)) {
      height: 100%;
      background: radial-gradient(
        circle at 50% 46%, 
        #0152a1 0.87%, 
        rgba(0, 0, 0, 0) 74%)
    }
    @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
      height: 100%;
      background: radial-gradient(
        circle at 50% 46%, 
        #0152a1 0.87%, 
        rgba(0, 0, 0, 0) 74%)
    }
  }
  .title-heading {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
  }
  h1.heading {
    width: 107rem;
    margin-bottom: 4rem;
    @media screen and (max-width: 1204px) {
      width: 100%;
    }
    @include breakpoint($large) {    
    margin-bottom: 5rem;
  }
  }
  h4 {
    padding-bottom: 45vh;
    text-align: center;
    font-weight: bold;
    margin: 0 auto;
    margin-top: 5rem;
    @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
      padding-bottom: 5vh;
      margin-top: 2rem;
    }
  }
  .grid-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1440px;
    margin: 0 auto;
    gap: 5px;
    @media screen and (max-width: ($mobile-breakpoint)) {     
      gap: 0;
    }     
    @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
      gap: 0;
    } 
    .cell {
      @media screen and (max-width: ($mobile-breakpoint)) {
        margin-bottom: 5px;       
      }
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        width: 100%;
        @include breakpoint($medium) {
          width: 33%;
        }
        @include iPad() {
          width: 100%;
        }
        @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {         
          width: calc(33% - 5px);
          margin-bottom: 5px;
          margin-right: 5px;
        }
      }      

      &:nth-child(4),
      &:nth-child(5) {
        width: 100%;
        @include breakpoint($medium) {
          width: calc(50% - 5px);
        }
        @include iPad() {
          width: 100%;
        }
        @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
          width: calc(50% - 5px);
          margin-bottom: 5px;
          margin-right: 5px;
        }
      }

      &:nth-child(3),
      &:nth-child(8) {
        @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
          margin-right: 0px;
          width: 34%;
        }
      }
      &:nth-child(5) {
        @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
          margin-right: 0px;
          width: calc(50% - 0px);
        }
      }
    }
  }
}

@media only screen 
and (min-device-width: 800px) 
and (max-device-width: 1366px) 
and (-webkit-min-device-pixel-ratio: 1.5)
and (orientation: landscape) {  
  .gx-section--seven {

    .grid-container {
      display: block;
      gap: 0;
      .cell {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          display: block;
          min-height: 275px;
          float: left;
          width: calc(33% - 5px);
          margin-bottom: 5px;
          margin-right: 5px;
        } 
        &:nth-child(4),
        &:nth-child(5) {
          display: block;
          min-height: 275px;
          float: left;
          width: calc(50% - 5px);
          margin-bottom: 5px;
          margin-right: 5px;
        }
      }
    }
  }
}


@media only screen 
and (min-device-width: 820px) 
and (max-device-width: 1366px) 
and (-webkit-min-device-pixel-ratio: 1.5)
and (orientation: portrait) {  
  .gx-section--seven {
    background: radial-gradient(
        circle at 50% 38%, 
      #006dd8 0.87%, 
      rgba(0, 0, 0, 0) 40%);
  }
  .gx-section--seven {

.grid-container {
  display: block;
  gap: 0;
  .cell {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      display: block;
      min-height: 275px;
      float: left;
      width: calc(33% - 5px);
      margin-bottom: 5px;
      margin-right: 5px;
    } 
    &:nth-child(4),
    &:nth-child(5) {
      display: block;
      min-height: 275px;
      float: left;
      width: calc(50% - 5px);
      margin-bottom: 5px;
      margin-right: 5px;
    }
  }
}
}
}
</style>
 