<template>
  <div class="sections">
    <gxHome :skipintrovideo="skipintrovideo" />
    <gxSectionTwo />
    <gxIpads1 />
    <gxIpads2 />
    <gxIpads3 />
    <gxSectionSix />
    <gxSectionSeven />
    <gxSectionMedia />
    <gxSectionClient />
    <gxSectionEight />
    <gxFooter />
  </div>
</template>

<script>
import gxHome from './gx-home.vue';
import gxSectionTwo from './gx-section2.vue';
import gxIpads1 from './gx-ipads1.vue';
import gxIpads2 from './gx-ipads2.vue';
import gxIpads3 from './gx-ipads3.vue';
import gxSectionSix from './gx-section6.vue'
import gxSectionSeven from './gx-section7.vue';
import gxSectionMedia from './gx-section-media.vue';
import gxSectionClient from './gx-section-client.vue';
import gxSectionEight from './gx-section8.vue';
import gxFooter from '../common/footer.vue'

export default ({
  props: {
    skipintrovideo: {
      type: Boolean,
      default: false
    }
  },
  components: {
    gxHome,
    gxSectionTwo,
    gxIpads1,
    gxIpads2,
    gxIpads3,
    gxSectionSix,
    gxSectionSeven,
    gxSectionMedia,
    gxSectionClient,
    gxSectionEight,
    gxFooter
  },
  mounted() {
  }
})
</script>

<style lang="scss">

</style>